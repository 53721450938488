import { gql } from "@apollo/client"
import { GQL_FRAGMENT_PAYMENT, GQL_FRAGMENT_RESERVATION, GQL_FRAGMENT_SLOT, GQL_FRAGMENT_USER } from "./Fragments"

// Settings
export const GQL_GET_API_INFOS = gql`
query {
    getApiInfos {
      apiVersion
      codeName
      coreVersion
      date
      environment
    }
  }
`

// Users
export const GQL_WHO_AM_I = gql`
${GQL_FRAGMENT_USER}
  query {
    whoami {
      ...User
    }
  }
`
export const GQL_USERS = gql`
${GQL_FRAGMENT_USER}
query users($searchQuery: String) {
  users(searchQuery: $searchQuery) {
    ...User
    }
  }
`
export const GQL_USER_BY_INVITATION_TOKEN = gql`
${GQL_FRAGMENT_USER}
query userByInvitationToken($invitationToken: String!) {
  userByInvitationToken(invitationToken: $invitationToken) {
    ...User
    }
  }
`


// Slots
export const GQL_SLOTS = gql`
${GQL_FRAGMENT_SLOT}
${GQL_FRAGMENT_RESERVATION}
${GQL_FRAGMENT_USER}
${GQL_FRAGMENT_PAYMENT}
query slots {
  slots {
    ...Slot
    accessibleReservations {
      ...Reservation
      user {
        ...User
      }
      payment {
        ...Payment
      }
      }
    }
  }
`
// Reservations
export const GQL_RESERVATIONS_OF_USER = gql`
${GQL_FRAGMENT_RESERVATION}
${GQL_FRAGMENT_USER}
${GQL_FRAGMENT_SLOT}
${GQL_FRAGMENT_PAYMENT}
query reservationsOfUser($userId: ID!) {
  reservationsOfUser(userId: $userId) {
    ...Reservation
    user {
      ...User
    }
    slot {
      ...Slot
    }
    payment {
      ...Payment
    }
  }
}
`

