import { FC, useEffect, useState } from 'react';
import { IconButton, Typography, useTheme } from "@mui/material";
import { IUser } from '../../interfaces';
import { SxProps } from "@mui/material";
import { Flexbox } from '../utils/Flexbox';
import { EnumMessageType, EnumUserLegalStatus } from '../../enums';
import { CopyToClipboard } from '../utils/CopyToClipboard';
import { NAVIGATE_INVIATION_PAGE } from '../../utils/Navigation';
import BusinessIcon from '@mui/icons-material/Business';
import { DeleteConfirmDialog } from '../dialogs/DeleteConfirmDialog';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_DELETE_AGENT, GQL_MUTATION_DELETE_USER } from '../../graphql/Mutations';
import { addMessage } from '../messages/Message';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../redux/features/users/usersSlice';

interface IProps {
    index: number,
    agent: IUser,
    sx?: SxProps,
}

export const AgentItem: FC<IProps> = (props) => {
    const [showDeleteAgentDialog, setShowDeleteAgentDialog] = useState(false)
    const [deleteAgent, { data: deleteAgentData, loading: deleteAgentLoading, error: deleteAgentError }] = useMutation(GQL_MUTATION_DELETE_AGENT)
    const theme = useTheme()
    const dispatch = useDispatch()

    const displayUserName = () => {
        return `${props.agent.firstname} ${props.agent.lastname}`
    }

    const closeDeleteAgentDialog = () => {
        setShowDeleteAgentDialog(false)
    }

    const confirmedDeleteAgent = () => {
        deleteAgent({
            variables: {
                agentId: props.agent.id
            }
        })
        setShowDeleteAgentDialog(false)
    }

    useEffect(() => {
        if (deleteAgentError) {
            addMessage({
                type: EnumMessageType.Error,
                message: deleteAgentError.message,
            })
        } else if (deleteAgentData) {
            if (deleteAgentData.deleteAgent.statusCode === 200) {
                dispatch(getUsers())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Agent supprimé.",
                })
            } else {
                deleteAgentData.deleteAgent.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAgentData, deleteAgentError])


    return (
        <>
            <Flexbox sx={{
                width: "calc(100% - 32px)",
                justifyContent: "space-between",
                mx: 4,
                px: 2,
                mb: 1,
                backgroundColor: props.index % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper
            }}>
                <Flexbox direction='column' sx={{ alignItems: "flex-start" }}>
                    <Flexbox sx={{ gap: 1 }}>
                        <BusinessIcon sx={{ color: theme.palette.primary.main }} />
                        <Typography sx={{ color: theme.palette.primary.main }}>
                            {props.agent.enterpriseName}
                        </Typography>

                    </Flexbox>
                    <Typography sx={{ fontSize: 12 }}>
                        {`${displayUserName()} ${props.agent.agencyDirector ? "(direction)" : ""}`}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                        {props.agent.email}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                        {props.agent.phone}
                    </Typography>
                    {
                        props.agent.invitationToken &&
                        <Typography sx={{ fontSize: 14 }}>
                            Lien d'invitation:
                            &nbsp;
                            <Typography component="span" sx={{ fontSize: 14, color: theme.palette.info.main }}>
                                {`${window.location.origin}${NAVIGATE_INVIATION_PAGE}?invitation_token=${props.agent.invitationToken}`}
                            </Typography>
                            &nbsp;
                            <CopyToClipboard
                                text={`${window.location.origin}${NAVIGATE_INVIATION_PAGE}?invitation_token=${props.agent.invitationToken}`}
                                message="Lien d'invitation copié."
                            />
                        </Typography>
                    }
                </Flexbox>
                <Flexbox direction='column'>
                    <IconButton color="error" onClick={() => setShowDeleteAgentDialog(true)}><DeleteOutlineOutlined /></IconButton>
                </Flexbox>
            </Flexbox>

            <DeleteConfirmDialog
                open={showDeleteAgentDialog}
                title="Suppression de l'agent"
                content={`Confirmez-vous la suppression définitive de l'agent ${displayUserName()} ?`}
                handleClose={closeDeleteAgentDialog}
                handleConfirm={confirmedDeleteAgent}
                danger={true}
            />
        </>
    )
}


