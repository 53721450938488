import { FC, useEffect, useState } from 'react'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from "./Layout"
import { MainTitle } from '../components/MainTitle'
import { IReservation } from '../interfaces'
import { useSelector } from 'react-redux'
import { IRootState } from '../redux/store'
import KRGlue from '@lyracom/embedded-form-glue'
import { NAVIGATE_PAYMENT_SUCCESS_PAGE } from '../utils/Navigation'
import { addMessage } from '../components/messages/Message'
import { EnumMessageType } from '../enums'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { GQL_MUTATION_SET_PAYZEN_ANSWER } from '../graphql/Mutations'

interface IProps {
}

export const PaymentPage: FC<IProps> = (props) => {
  const formToken: string = useSelector((state: IRootState) => state.paymentReducer.formToken)
  const reservation: IReservation | undefined = useSelector((state: IRootState) => state.paymentReducer.reservation)
  const [orderStatus, setorderStatus] = useState("unknown")
  const ORDER_STATUS_PAID = "PAID"
  const [setPayzenClientAnswer, { data: setPayzenClientAnswerData, loading: setPayzenClientAnswerLoading, error: setPayzenClientAnswerError }] = useMutation(GQL_MUTATION_SET_PAYZEN_ANSWER)

  const navigate = useNavigate()

  const onPaid = (event: any) => {
    // console.log("event.clientAnswer: ", event.clientAnswer)
    setorderStatus(event.clientAnswer.orderStatus)
    if (event.clientAnswer.orderStatus === ORDER_STATUS_PAID) {
      // Remove the payment form
      // KR.removeForms();
      addMessage({
        type: EnumMessageType.Success,
        message: "Paiement effectué."
      })
    } else {
      addMessage({
        type: EnumMessageType.Error,
        message: "Erreur de paiement !"
      })
    }
    if (reservation) {
      setPayzenClientAnswer({
        variables: {
          reservationId: reservation.id,
          payzenClientAnswer: JSON.stringify(event.clientAnswer),
        }
      })
    }
  }

  useEffect(() => {
    if (setPayzenClientAnswerError) {
      addMessage({
        type: EnumMessageType.Error,
        message: setPayzenClientAnswerError.message,
      })
    } else if (setPayzenClientAnswerData) {
      if (setPayzenClientAnswerData.setPayzenAnswer.statusCode === 200) {
        if (orderStatus === ORDER_STATUS_PAID) {
          navigate(NAVIGATE_PAYMENT_SUCCESS_PAGE)
        }

      } else {
        setPayzenClientAnswerData.setPayzenAnswer.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [setPayzenClientAnswerData, setPayzenClientAnswerError])

  useEffect(() => {
    async function setupPaymentForm() {
      const endpoint = 'https://static.payzen.eu'
      const publicKey = process.env.REACT_APP_PAYZEN_PUBLIC_KEY || ""

      try {
        // const res = await fetch('http://localhost:3000/createPayment', {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({
        //     paymentConf: { amount: 10000, currency: 'USD' }
        //   })
        // })
        // formToken = await res.text()

        const { KR } = await KRGlue.loadLibrary(
          endpoint,
          publicKey
        ) /* Load the remote library */

        await KR.setFormConfig({
          /* set the minimal configuration */ formToken: formToken,
          // 'kr-language': 'en-US' /* to update initialization parameter */
          // 'kr-post-url-success': NAVIGATE_PAYMENT_SUCCESS_PAGE,
        })

        // await KR.onSubmit(async paymentData => {
        //   const response = await fetch(
        //     'http://localhost:3000/validatePayment',
        //     {
        //       method: 'POST',
        //       headers: { 'Content-Type': 'application/json' },
        //       body: JSON.stringify(paymentData)
        //     }
        //   )
        //   if (response.status === 200) setMessage('Payment successful!')
        //   return false // Return false to prevent the redirection
        // })
        KR.onSubmit(onPaid)

        await KR.renderElements(
          '#myPaymentForm'
        ) /* Render the payment form into myPaymentForm div*/
      } catch (error) {
        // setMessage(error + ' (see console for more details)')
        addMessage({
          type: EnumMessageType.Error,
          message: `Erreur de paiement: ${error}`
        })
      }
    }

    setupPaymentForm()
  }, [formToken])

  return (
    <div className="paymentPage">
      <Layout>
        <Flexbox direction='column'>
          <MainTitle title="Paiement" />
          {/* <div className="kr-embedded" kr-form-token={formToken}></div> */}
          <div id="myPaymentForm">
            <div className="kr-smart-form" kr-card-form-expanded="true"></div>
          </div>
        </Flexbox>
      </Layout>
    </div >
  )
}
