import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';

import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { removeJwt } from '../redux/features/global/apolloSlice';
import { IRootState } from '../redux/store';
import { addMessage } from './messages/Message';
import { IUser } from '../interfaces';
import { EnumMessageType } from '../enums';
import { useTheme } from '@mui/material';
import { NAVIGATE_ADMIN_PAGE, NAVIGATE_AGENCY_PAGE, NAVIGATE_LOGIN_PAGE, NAVIGATE_ROOT, NAVIGATE_SIGNUP_PAGE } from '../utils/Navigation';
import { unsetCurrentUser, useGetCurrentUser } from '../redux/features/users/currentUserSlice';

export const AvatarMenu = () => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const currentUser: (IUser | undefined) = useSelector((state: IRootState) => state.currentUserReducer.user)
    const currentUser = useGetCurrentUser()

    const theme = useTheme()
    const hasJwt = useSelector((state: IRootState) => state.apolloReducer.hasJwt)

    // React.useEffect(() => {
    //     if (!hasJwt) {
    //         // Il n'y a pas de JWT, on redirect sur login..
    //         // Ne pas mettre de message à cause des redirects..
    //         navigate(`${NAVIGATE_LOGIN}`)
    //     }
    // }, [])

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = (withMessage: boolean = false) => {
        setAnchorElUser(null);
        dispatch(removeJwt())
        dispatch(unsetCurrentUser())
        if (withMessage) {
            addMessage({
                type: EnumMessageType.Success,
                message: "Déconnexion effectuée.",
            })
        }
        // Avec la correction de context de jwt, il n'est plus nécessaire de faire de navigate.
        // Cependant, pour le logout, on navigate quand même au cas où le user serait sur la partie admin.
        navigate(NAVIGATE_ROOT)
    }

    return (
        <Box>
            <IconButton onClick={handleOpenUserMenu} sx={{ px: 0 }}>
                <Avatar
                    className="avatar-icon"
                    alt={`${currentUser?.firstname} ${currentUser?.lastname}`}
                    src={currentUser ? "#" : `${process.env.PUBLIC_URL}/images/anonymous_avatar.png`}
                    sx={{
                        display: "flex",
                        backgroundColor: currentUser ? theme.palette.secondary.main : theme.palette.grey[400],
                    }}
                ></Avatar>
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                id="avatar-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {
                    currentUser && currentUser.admin &&
                    <MenuItem onClick={() => navigate(NAVIGATE_ADMIN_PAGE)}>
                        <Typography textAlign="center">Admin</Typography>
                    </MenuItem>
                }
                {
                    currentUser && currentUser.agencyDirector &&
                    <MenuItem onClick={() => navigate(NAVIGATE_AGENCY_PAGE)}>
                        <Typography textAlign="center">Agence</Typography>
                    </MenuItem>
                }
                {
                    hasJwt &&
                    <MenuItem onClick={() => logout(true)}>
                        <Typography textAlign="center">Se déconnecter</Typography>
                    </MenuItem>
                }
                {
                    !hasJwt &&
                    <MenuItem onClick={() => navigate(NAVIGATE_LOGIN_PAGE)}>
                        <Typography textAlign="center">Se connecter</Typography>
                    </MenuItem>
                }
                {
                    !hasJwt &&
                    <MenuItem onClick={() => navigate(NAVIGATE_SIGNUP_PAGE)}>
                        <Typography textAlign="center">S'inscrire</Typography>
                    </MenuItem>
                }
            </Menu>
        </Box>
    );
};
