// import "../css/components/MetadocLogo.css"
import { FC, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";


import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';



interface IProps {
    visible: boolean,
    activateOnLoad: boolean,
}


export const ScrollToTop: FC<IProps> = (props) => {

    const theme = useTheme()

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (props.visible) {
            window.addEventListener('scroll', toggleVisibility);
            return () => {
                window.removeEventListener('scroll', toggleVisibility);
            };
        }
        if (props.activateOnLoad) {
            scrollToTop()
        }
    }, []);


    return (
        //     <div style={{ display: isVisible ? 'block' : 'none', position: 'fixed', bottom: 20, right: 20 }}>
        //   <IconButton onClick={scrollToTop}>
        //     <KeyboardArrowUpIcon />
        //   </IconButton>
        // </div>
        <Box sx={{ display: isVisible ? 'block' : 'none', position: 'fixed', bottom: 20, right: 20 }}>
            <IconButton onClick={scrollToTop} sx={{ color: theme.palette.secondary.main }}>
                <KeyboardArrowUpIcon />
            </IconButton>
        </Box>
    )

}


