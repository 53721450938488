import { FC, useEffect } from 'react';
import { useTheme } from "@mui/material";
import { Flexbox } from '../utils/Flexbox';
import { useDispatch } from 'react-redux';
import { Spinner } from '../Spiner';
import { setCurrentUserReservations, useGetCurrentUser } from '../../redux/features/users/currentUserSlice';
import { apolloClient } from '../../App';
import { MyReservationItem } from './MyReservationItem';
import { GQL_RESERVATIONS_OF_USER } from '../../graphql/Queries';
import { useGetFilteredReservations } from '../../redux/features/global/globalSlice';

interface IProps {
}

export const ListReservations: FC<IProps> = (props) => {
    const currentUser = useGetCurrentUser()
    const theme = useTheme()
    const dispatch = useDispatch()
    const filteredReservations = useGetFilteredReservations(currentUser?.reservations)

    useEffect(() => {
        if (currentUser) {
            apolloClient.query({
                query: GQL_RESERVATIONS_OF_USER,
                fetchPolicy: 'network-only',
                variables: {
                    userId: currentUser.id,
                }
            }
            ).then(response => {
                dispatch(setCurrentUserReservations(response.data.reservationsOfUser))
            })
        }
    }, [currentUser])


    return (
        <>
            <Flexbox direction='column' sx={{
                width: "100%",
                maxWidth: "1024px",
                backgroundColor: "white",
                px: 2,
                pb: 1,
                borderRadius: 4,
            }}>
                <Flexbox direction='column' sx={{ width: "100%" }}>
                    {
                        currentUser &&
                        <>
                            {
                                currentUser.reservations === undefined ? <Spinner /> : <>
                                    {
                                        // currentUser.reservations.map((reservation, index) => {
                                        //     return (
                                        //         <MyReservationItem index={index} key={reservation.id} reservation={reservation} sx={{ py: 1 }} />
                                        //     )
                                        // })
                                        filteredReservations.map((reservation, index) => {
                                            return (
                                                <MyReservationItem index={index} key={reservation.id} reservation={reservation} sx={{ py: 1 }} />
                                            )
                                        })

                                    }
                                </>

                            }
                        </>
                    }
                </Flexbox>
            </Flexbox>
        </>
    )
}


