import { gql } from "@apollo/client"
import { GQL_FRAGMENT_PAYMENT, GQL_FRAGMENT_RESERVATION, GQL_FRAGMENT_USER } from "./Fragments"

// Login
export const GQL_MUTATION_LOGIN = gql`
${GQL_FRAGMENT_USER}
  mutation login($email: String!, $password: String!) {
    login(input: {credentials: {email: $email, password: $password}}) {
      authToken
      user{
        ...User
      }
      statusCode
      status
      errors
    }
  }
`

// Users
export const GQL_MUTATION_SIGNUP = gql`
${GQL_FRAGMENT_USER}
  mutation signup(
    $firstname: String!,
    $lastname: String!,
    $phone: String!,
    $email: String!,
    $password: String!,
    $comment: String,
    ) {
    signup(input: {
      firstname: $firstname,
      lastname: $lastname,
      phone: $phone,
      email: $email,
      password: $password,
      comment: $comment,
    }) {
      authToken
      user{
        ...User
      }
      statusCode
      status
      errors
    }
  }
`

export const GQL_MUTATION_INVITE_USER = gql`
${GQL_FRAGMENT_USER}
  mutation inviteUser(
    $legalStatus: EnumUserLegalStatus!,
    $enterpriseName: String,
    $firstname: String!,
    $lastname: String!,
    $phone: String!,
    $email: String!,
    $comment: String,
    ) {
    inviteUser(input: {
      legalStatus: $legalStatus,
      enterpriseName: $enterpriseName,
      firstname: $firstname,
      lastname: $lastname,
      phone: $phone,
      email: $email,
      comment: $comment,
    }) {
      user{
        ...User
      }
      statusCode
      status
      errors
    }
  }
`
export const GQL_MUTATION_DELETE_USER = gql`
mutation deleteUser(
  $userId: ID!,
  ) {
  deleteUser(input: {
    userId: $userId,
  }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_CHOOSE_PASSWORD = gql`
${GQL_FRAGMENT_USER}
  mutation choosePassword(
    $userId: ID!,
    $invitationToken: String!,
    $password: String!,
    ) {
    choosePassword(input: {
      userId: $userId,
      invitationToken: $invitationToken,
      password: $password,
    }) {
      authToken
      user{
        ...User
      }
      statusCode
      status
      errors
    }
  }
`

export const GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL = gql`
  mutation sendForgottenPasswordMail($email: String!) {
    sendForgottenPasswordMail(input: {email: $email}) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_RESET_PASSWORD = gql`
mutation resetPassword($email: String!, $passwordResetToken: String!, $newPassword: String!) {
  resetPassword(input: {email: $email, passwordResetToken: $passwordResetToken, newPassword: $newPassword}) {
    statusCode
    errors
  }
}
`

// Slots
export const GQL_MUTATION_CREATE_SLOT = gql`
mutation createSlot(
  $occurredAt: String!,
  $nbPlaces: Int!,
  $price: Float!,
  $companionPrice: Float!,
  ) {
  createSlot(input: {
    occurredAt: $occurredAt,
    nbPlaces: $nbPlaces,
    price: $price,
    companionPrice: $companionPrice,
  }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_SLOT = gql`
mutation deleteSlot(
  $slotId: ID!,
  ) {
  deleteSlot(input: {
    slotId: $slotId,
  }) {
    statusCode
    errors
  }
}
`

// Reservations
export const GQL_MUTATION_CREATE_RESERVATION = gql`
${GQL_FRAGMENT_RESERVATION}
${GQL_FRAGMENT_PAYMENT}
mutation createReservation(
  $slotId: ID!,
  $nbPersons: Int!,
  $nbCompanions: Int!,
  $refPersonLastname: String,
  $refPersonFirstname: String,
  $refPersonEmail: String,
  $refPersonPhone: String,
  ) {
  createReservation(input: {
    slotId: $slotId,
    nbPersons: $nbPersons,
    nbCompanions: $nbCompanions,
    refPersonLastname: $refPersonLastname,
    refPersonFirstname: $refPersonFirstname,
    refPersonEmail: $refPersonEmail,
    refPersonPhone: $refPersonPhone,
  }) {
    reservation {
      ...Reservation,
      payment {
        ...Payment
        payzenFormToken {
          payzenFormTokenAnswer {
            formToken
          }
        }
      }
    }
    statusCode
    errors
  }
}
`

// Payments
export const GQL_MUTATION_SET_PAYZEN_ANSWER = gql`
mutation setPayzenAnswer(
  $reservationId: ID!,
  $payzenClientAnswer: String!,
  ) {
  setPayzenAnswer(input: {
    reservationId: $reservationId,
    payzenClientAnswer: $payzenClientAnswer,
  }) {
    statusCode
    errors
  }
}
`

// Agents
export const GQL_MUTATION_INVITE_AGENT = gql`
${GQL_FRAGMENT_USER}
  mutation inviteAgent(
    $firstname: String!,
    $lastname: String!,
    $phone: String!,
    $email: String!,
    $comment: String,
    ) {
    inviteAgent(input: {
      firstname: $firstname,
      lastname: $lastname,
      phone: $phone,
      email: $email,
      comment: $comment,
    }) {
      user{
        ...User
      }
      statusCode
      status
      errors
    }
  }
`

export const GQL_MUTATION_DELETE_AGENT = gql`
mutation deleteAgent(
  $agentId: ID!,
  ) {
  deleteAgent(input: {
    agentId: $agentId,
  }) {
    statusCode
    errors
  }
}
`