import { FC, useEffect, useState } from 'react';
import { IconButton, Typography, useTheme } from "@mui/material";
import { IUser } from '../../interfaces';
import { SxProps } from "@mui/material";
import { Flexbox } from '../utils/Flexbox';
import { EnumMessageType, EnumUserLegalStatus } from '../../enums';
import { CopyToClipboard } from '../utils/CopyToClipboard';
import { NAVIGATE_INVIATION_PAGE } from '../../utils/Navigation';
import BusinessIcon from '@mui/icons-material/Business';
import { DeleteConfirmDialog } from '../dialogs/DeleteConfirmDialog';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_DELETE_USER } from '../../graphql/Mutations';
import { addMessage } from '../messages/Message';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../redux/features/users/usersSlice';

interface IProps {
    index: number,
    user: IUser,
    sx?: SxProps,
}

export const UserItem: FC<IProps> = (props) => {
    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false)
    const [deleteUser, { data: deleteUserData, loading: deleteUserLoading, error: deleteUserError }] = useMutation(GQL_MUTATION_DELETE_USER)
    const theme = useTheme()
    const dispatch = useDispatch()

    const displayUserName = () => {
        // if (props.user.legalStatus === EnumUserLegalStatus.enterprise) {
        //     return props.user.enterpriseName
        // } else {
        //     return `${props.user.firstname} ${props.user.lastname}`
        // }
        return `${props.user.firstname} ${props.user.lastname}`
    }

    const closeDeleteUserDialog = () => {
        setShowDeleteUserDialog(false)
    }

    const confirmedDeleteUser = () => {
        deleteUser({
            variables: {
                userId: props.user.id
            }
        })
        setShowDeleteUserDialog(false)
    }

    useEffect(() => {
        if (deleteUserError) {
            addMessage({
                type: EnumMessageType.Error,
                message: deleteUserError.message,
            })
        } else if (deleteUserData) {
            if (deleteUserData.deleteUser.statusCode === 200) {
                dispatch(getUsers())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Utilisateur supprimé.",
                })
            } else {
                deleteUserData.deleteUser.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteUserData, deleteUserError])


    return (
        <>
            <Flexbox sx={{
                width: "calc(100% - 32px)",
                justifyContent: "space-between",
                mx: 4,
                px: 2,
                mb: 1,
                backgroundColor: props.index % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper
            }}>
                <Flexbox direction='column' sx={{ alignItems: "flex-start" }}>
                    {
                        props.user.legalStatus === EnumUserLegalStatus.enterprise &&
                        <>
                            <Flexbox sx={{ gap: 1 }}>
                                <BusinessIcon sx={{ color: theme.palette.primary.main }} />
                                <Typography sx={{ color: theme.palette.primary.main }}>
                                    {props.user.enterpriseName}
                                </Typography>

                            </Flexbox>
                            <Typography sx={{ fontSize: 12 }}>
                                {/* {`${props.user.firstname} ${props.user.lastname} ${props.user.agencyDirector ? "(direction)" : ""}`} */}
                                {`${displayUserName()} ${props.user.agencyDirector ? "(direction)" : ""}`}
                            </Typography>
                        </>

                    }
                    {
                        props.user.legalStatus === EnumUserLegalStatus.particular &&
                        <Typography sx={{ color: theme.palette.primary.main }}>
                            {displayUserName()}
                        </Typography>
                    }
                    <Typography sx={{ fontSize: 12 }}>
                        {props.user.email}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                        {props.user.phone}
                    </Typography>
                    {
                        props.user.invitationToken &&
                        <Typography sx={{ fontSize: 14 }}>
                            Lien d'invitation:
                            &nbsp;
                            <Typography component="span" sx={{ fontSize: 14, color: theme.palette.info.main }}>
                                {`${window.location.origin}${NAVIGATE_INVIATION_PAGE}?invitation_token=${props.user.invitationToken}`}
                            </Typography>
                            &nbsp;
                            <CopyToClipboard
                                text={`${window.location.origin}${NAVIGATE_INVIATION_PAGE}?invitation_token=${props.user.invitationToken}`}
                                message="Lien d'invitation copié."
                            />
                        </Typography>
                    }
                </Flexbox>
                <Flexbox direction='column'>
                    <IconButton color="error" onClick={() => setShowDeleteUserDialog(true)}><DeleteOutlineOutlined /></IconButton>
                </Flexbox>
            </Flexbox>

            <DeleteConfirmDialog
                open={showDeleteUserDialog}
                title="Suppression de l'utilisateur"
                content={`Confirmez-vous la suppression définitive de l'utilisateur ${displayUserName()} ?`}
                handleClose={closeDeleteUserDialog}
                handleConfirm={confirmedDeleteUser}
                danger={true}
            />
        </>
    )
}


