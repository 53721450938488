import { FC } from "react"
import { EnumMazenieneLogoMode } from "../enums"
import { Box, SxProps, Typography, useTheme } from "@mui/material"
import { Flexbox } from "./utils/Flexbox"
import { MazenieneIcon } from "./svg_icons/MazenieneIcon"



interface IProps {
    mode: EnumMazenieneLogoMode,
    sx?: SxProps,
}


export const MazenieneLogo: FC<IProps> = (props) => {

    const theme = useTheme()

    const StandardVersion = () =>
        <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/logo_only_512.png`}
            sx={{
                width: { xs: "150px" },
                mx: 2,
            }}
        />

    const AccueilVersion = () =>
        <Flexbox {...props}>
            <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/images/logo_only_512.png`}
                sx={{
                    width: { xs: "70px" },
                    mr: 2,
                }}
            />
            <Flexbox direction="column">
                <Typography
                    sx={{
                        fontFamily: theme.typography.tertiary,
                        fontWeight: "bold",
                        // color: theme.palette.secondary.main,
                        color: "black",
                        // fontSize: 28,
                        fontSize: 36,
                    }}
                >
                    Le Mazéniène
                </Typography>
                <Typography
                    sx={{
                        fontFamily: theme.typography.quaternary,
                        color: theme.palette.gris4.main,
                        fontSize: 16,
                        mt: -1.5,
                    }}
                >
                    Restaurant & Bar
                </Typography>
            </Flexbox>
        </Flexbox>
    const AccueilMobileVersion = () =>
        <Flexbox {...props}>
            <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/images/logo_only_512.png`}
                sx={{
                    width: { xs: "50px" },
                    mr: 2,
                }}
            />
            <Flexbox direction="column">
                <Typography
                    sx={{
                        // color: theme.palette.secondary.main,
                        // fontSize: 20,
                        fontFamily: theme.typography.tertiary,
                        fontWeight: "bold",
                        color: "black",
                        fontSize: 28,
                    }}
                >
                    Le Mazéniène
                </Typography>
                <Typography
                    sx={{
                        // color: theme.palette.tertiary.main,
                        // fontSize: 14,
                        // mt: -1,
                        fontFamily: theme.typography.quaternary,
                        color: theme.palette.gris4.main,
                        fontSize: 13,
                        mt: -1.5,
                    }}
                >
                    Restaurant & Bar
                </Typography>
            </Flexbox>
        </Flexbox>

    return (
        <>
            {
                props.mode === EnumMazenieneLogoMode.standard && <StandardVersion />
            }
            {
                props.mode === EnumMazenieneLogoMode.accueil && <AccueilVersion />
            }
            {
                props.mode === EnumMazenieneLogoMode.accueilMobile && <AccueilMobileVersion />
            }

        </>
    )

}


