import { Button, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { IReservation } from '../../interfaces';
import { Flexbox } from '../utils/Flexbox';
import { EnumPaymentStatus, EnumUserLegalStatus } from '../../enums';
import BusinessIcon from '@mui/icons-material/Business';


interface IProps {
    index: number,
    reservation: IReservation,
}

export const ReservationItem: FC<IProps> = (props) => {
    const theme = useTheme()

    const displayReferenteInfos = () => {
        let res = `${props.reservation.refPersonFirstname} ${props.reservation.refPersonLastname}`
        if (props.reservation.refPersonEmail) {
            res = `${res} (${props.reservation.refPersonEmail})`
        }
        return res
    }

    return (
        <>
            <Flexbox sx={{
                justifyContent: "space-between",
                px: 2,
                mb: 1,
                backgroundColor: props.index % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper
            }}>
                <Flexbox direction='column' sx={{ alignItems: "flex-start" }}>
                    {
                        props.reservation.user.legalStatus === EnumUserLegalStatus.enterprise &&
                        <>
                            {/* <Typography sx={{ color: theme.palette.primary.main }}>
                                {props.reservation.user.enterpriseName}
                            </Typography> */}
                            <Flexbox sx={{ gap: 1 }}>
                                <BusinessIcon sx={{ color: theme.palette.primary.main }} />
                                <Typography sx={{ color: theme.palette.primary.main }}>
                                    {props.reservation.user.enterpriseName}
                                </Typography>

                            </Flexbox>
                            <Typography sx={{ fontSize: 12 }}>
                                {`${props.reservation.user.firstname} ${props.reservation.user.lastname}`}
                            </Typography>
                        </>

                    }
                    {
                        props.reservation.user.legalStatus === EnumUserLegalStatus.particular &&
                        <>
                            <Typography sx={{ color: theme.palette.primary.main }}>
                                {`${props.reservation.user.firstname} ${props.reservation.user.lastname}`}
                            </Typography>
                        </>
                    }
                    <Typography sx={{ fontSize: 12 }}>
                        {props.reservation.user.email}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                        {props.reservation.user.phone}
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontStyle: "italic" }}>
                        {`Nº de réservation: ${props.reservation.orderNumber}`}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                        {props.reservation.nbPersons} personnes / {props.reservation.nbCompanions} accompagnants
                    </Typography>
                    {
                        props.reservation.user.legalStatus === EnumUserLegalStatus.enterprise && <>
                            <Typography sx={{ fontSize: 14 }}>
                                {`Référente: ${displayReferenteInfos()}`}
                            </Typography>
                        </>
                    }
                    {
                        props.reservation.user.legalStatus === EnumUserLegalStatus.particular && <>
                            {
                                !props.reservation.payment &&
                                <Typography sx={{ fontSize: 14, color: theme.palette.error.main }}>
                                    <span title={`Order de paiement: ${props.reservation.id}`}>
                                        Pas de paiement !
                                    </span>
                                </Typography>
                            }
                            {
                                props.reservation.payment &&
                                props.reservation.payment.paymentStatus === EnumPaymentStatus.UNKNOWN &&
                                <Typography sx={{ fontSize: 14, color: theme.palette.error.main }}>
                                    <span title={`Order de paiement: ${props.reservation.id}`}>
                                        Statut de paiement inconnu !
                                    </span>
                                </Typography>
                            }
                            {
                                props.reservation.payment &&
                                props.reservation.payment.paymentStatus === EnumPaymentStatus.PAID &&
                                <Typography sx={{ fontSize: 14, color: theme.palette.success.main }}>
                                    <span title={`Order de paiement: ${props.reservation.id}`}>
                                        Payé
                                    </span>
                                </Typography>
                            }
                            {
                                props.reservation.payment &&
                                props.reservation.payment.paymentStatus === EnumPaymentStatus.UNPAID &&
                                <Typography sx={{ fontSize: 14, color: theme.palette.error.main }}>
                                    <span title={`Order de paiement: ${props.reservation.id}`}>
                                        Non payé !
                                    </span>
                                </Typography>
                            }
                        </>
                    }
                </Flexbox>
            </Flexbox>
        </>
    )
}
