import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { NAVIGATE_AGENCY_AGENTS_PAGE, NAVIGATE_AGENCY_PAGE, NAVIGATE_ROOT, isActivePath } from '../utils/Navigation';
import { Link, useTheme } from '@mui/material';
import { MazenieneLogo } from './MazenieneLogo';
import { EnumMazenieneLogoMode } from '../enums';
import { AvatarMenu } from './AvatarMenu';

interface IPage {
    title: string
    path: string
}


const pages: IPage[] = [
    {
        title: 'Agence',
        path: NAVIGATE_AGENCY_PAGE,
    },
    {
        title: 'Agents',
        path: NAVIGATE_AGENCY_AGENTS_PAGE,
    },
];

const AgencyAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleButtonNavMenu = (page: IPage) => {
        setAnchorElNav(null);
        navigate(page.path)
    };

    return (
        <AppBar className='ResponsiveAppBar' position='relative' sx={{ backgroundColor: theme.palette.gris3.main }}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    {/* mobile */}
                    <Box sx={{ width: '100%', display: { xs: 'flex', md: 'none', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Typography
                            component="a"
                            href={NAVIGATE_ROOT}
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <MazenieneLogo mode={EnumMazenieneLogoMode.accueilMobile} />
                        </Typography>
                        <Box>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="tertiary"
                                className={`menu-icon ${Boolean(anchorElNav) ? "active" : ""}`}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    // color: theme.palette.primary.main,
                                }}
                                slotProps={{
                                    paper: {
                                        sx: {
                                            ">ul": {
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                            }

                                        }
                                    }
                                }}

                            >
                                {pages.map((page, index) => (
                                    <MenuItem key={index} onClick={handleCloseNavMenu}
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                        }}
                                    >
                                        <Link sx={{ textDecoration: "none" }} color={page.title === 'Accueil' ? "primary" : "secondary"} onClick={() => handleButtonNavMenu(page)} className={`${isActivePath(page.path, location.pathname) ? "active" : ""}`}>
                                            {page.title}
                                        </Link>
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <AvatarMenu />
                                </MenuItem>
                            </Menu>
                        </Box>

                    </Box>

                    {/* desktop */}
                    <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href={NAVIGATE_ROOT}
                            sx={{
                                mr: 2,
                                display: { alignItems: 'center' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'black',
                                textDecoration: 'none',
                            }}
                            className="menu-link"
                        >
                            <MazenieneLogo mode={EnumMazenieneLogoMode.accueil} />
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {pages.map((page, index) => (
                                <Button
                                    key={index}
                                    onClick={() => handleButtonNavMenu(page)}
                                    sx={{
                                        my: 2,
                                        fontSize: "18px",
                                        fontWeight: page.title === 'Accueil' ? "bold" : "normal",
                                        color: theme.palette.tertiary.main,
                                        ":hover": {
                                            color: theme.palette.secondary.main,
                                        }
                                    }}
                                >
                                    {page.title}
                                </Button>
                            ))}
                            <AvatarMenu />
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default AgencyAppBar;
