import { Box, Button, Typography, useTheme } from '@mui/material';
import { FC, ReactElement, useEffect } from 'react';
import { Messages } from '../../components/messages/Messages';
import { Footer } from '../../sections/Footer';
import AgencyAppBar from '../../components/AgencyAppBar';
import { IUser } from '../../interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { addMessage } from '../../components/messages/Message';
import { EnumMazenieneLogoMode, EnumMessageType } from '../../enums';
import { NAVIGATE_ROOT } from '../../utils/Navigation';
import { Flexbox } from '../../components/utils/Flexbox';
import { MazenieneLogo } from '../../components/MazenieneLogo';
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice';


interface IProps {
  children: ReactElement | ReactElement[];
}

export const AgencyLayout: FC<IProps> = (props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const currentUser = useGetCurrentUser()

  // useEffect(() => {
  //   if (currentUser && !currentUser.admin) {
  //     console.log("currentUser: ", currentUser)
  //     addMessage({
  //       type: EnumMessageType.Error,
  //       message: "Accès interdit.",
  //     })
  //     navigate(NAVIGATE_ROOT)
  //   }
  // }, [currentUser])



  return (
    <Box className="AgencyLayout" sx={{ backgroundColor: theme.palette.background.default }}>
      {
        (!currentUser || !currentUser.agencyDirector) && <>
          <Flexbox direction='column'>
            <MazenieneLogo mode={EnumMazenieneLogoMode.standard} sx={{ width: "100px", mt: 4 }} />
            <Typography>
              Accès réservé aux directeurs d'agences.
            </Typography>
            <Button variant='text' color='primary' onClick={() => navigate(NAVIGATE_ROOT)}>
              Accueil
            </Button>
          </Flexbox>
        </>
      }
      {
        currentUser && currentUser.agencyDirector && <>
          <Messages />
          <AgencyAppBar />
          <Box className='page-container' sx={{ minHeight: "800px", px: "0 !important" }}>
            {props.children}
          </Box>
          <Footer />
        </>
      }
    </Box>
  )
}
