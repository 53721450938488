import { createSlice } from "@reduxjs/toolkit";

export const MAZENIENE_WEB_JWT_TOKEN = "mazeniene_web_jwt_token"



interface IApolloSliceState {
    hasJwt: Boolean;
}

const initialState: IApolloSliceState = {
    hasJwt: localStorage.getItem(MAZENIENE_WEB_JWT_TOKEN) ? true : false
}

export const apolloSlice = createSlice({
    name: "apolloSlice",
    initialState,
    reducers: {
        setJwt: (state, action) => {
            localStorage.setItem(MAZENIENE_WEB_JWT_TOKEN, action.payload)
            state.hasJwt = true
        },
        removeJwt: (state) => {
            localStorage.removeItem(MAZENIENE_WEB_JWT_TOKEN)
            state.hasJwt = false
        },
    }
})

export const { setJwt, removeJwt } = apolloSlice.actions

export default apolloSlice.reducer