import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Flexbox } from '../utils/Flexbox';
import { useNavigate } from 'react-router-dom';
import { NAVIGATE_PAYMENT_PAGE, NAVIGATE_WORKSHOP_PAGE } from '../../utils/Navigation';
import { ISlot } from '../../interfaces';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { EnumDayjsFormat, EnumMessageType, EnumUserLegalStatus } from '../../enums';
import { renderEuros } from '../../utils/helpers';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_CREATE_RESERVATION } from '../../graphql/Mutations';
import { addMessage } from '../messages/Message';
import { useDispatch } from 'react-redux';
import { setFormToken, setReservation } from '../../redux/features/payments/paymentSlice';
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice';
import { getSlots } from '../../redux/features/global/globalSlice';


interface IProps {
    open: boolean,
    handleClose: () => void,
    slotOfDay: ISlot,
}

export const CreateReservationDialog: FC<IProps> = (props) => {
    const [nbPersons, setNbPersons] = useState<string>("1")
    const [errorOnNbPersons, setErrorOnNbPersons] = useState(false)
    const [calculatedPrice, setCalculatedPrice] = useState<number>(1 * props.slotOfDay.price)
    const [nbCompanions, setNbCompanions] = useState<string>("0")
    const [errorOnNbCompanions, setErrorOnNbCompanions] = useState(false)
    const [calculatedCompanionPrice, setCalculatedCompanionPrice] = useState<number>(0 * props.slotOfDay.companionPrice)
    const [createReservation, { data: createReservationData, loading: createReservationLoading, error: createReservationError }] = useMutation(GQL_MUTATION_CREATE_RESERVATION)

    const [refPersonLastname, setRefPersonLastname] = useState<string>("")
    const [errorOnRefPersonLastname, setErrorOnRefPersonLastname] = useState(false)
    const [refPersonFirstname, setRefPersonFirstname] = useState<string>("")
    const [errorOnRefPersonFirstname, setErrorOnRefPersonFirstname] = useState(false)
    const [refPersonEmail, setRefPersonEmail] = useState<string>("")
    const [refPersonPhone, setRefPersonPhone] = useState<string>("")
    const [errorOnRefPersonPhone, setErrorOnRefPersonPhone] = useState(false)

    const currentUser = useGetCurrentUser()

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChangeNbPersons = (newNbPersons: string) => {
        setNbPersons(newNbPersons)
        const intNewNbPersons = parseInt(newNbPersons)
        if (!isNaN(intNewNbPersons)) {
            setCalculatedPrice(parseInt(newNbPersons) * props.slotOfDay.price)
        }
    }

    const handleChangeNbCompanions = (newNbCompanions: string) => {
        setNbCompanions(newNbCompanions)
        const intNewNbNbCompanions = parseInt(newNbCompanions)
        if (!isNaN(intNewNbNbCompanions)) {
            setCalculatedCompanionPrice(parseInt(newNbCompanions) * props.slotOfDay.companionPrice)
        }
        // }
    }

    const checkErrors = () => {
        let res = true
        setErrorOnNbPersons(false)
        setErrorOnNbCompanions(false)
        setErrorOnRefPersonLastname(false)
        setErrorOnRefPersonFirstname(false)
        setErrorOnRefPersonPhone(false)
        const intNbPersons = parseInt(nbPersons)
        if (isNaN(intNbPersons)) {
            res = false
            setErrorOnNbPersons(true)
        }
        const intNbCompanions = parseInt(nbCompanions)
        if (isNaN(intNbCompanions)) {
            res = false
            setErrorOnNbCompanions(true)
        }
        if (currentUser?.legalStatus === EnumUserLegalStatus.enterprise) {
            if (refPersonLastname.length < 1) {
                res = false
                setErrorOnRefPersonLastname(true)
            }
            if (refPersonFirstname.length < 1) {
                res = false
                setErrorOnRefPersonFirstname(true)
            }
            if (refPersonPhone.length < 1) {
                res = false
                setErrorOnRefPersonPhone(true)
            }
        }


        return res
    }

    const handleConfirm = () => {
        if (checkErrors()) {
            createReservation({
                variables: {
                    slotId: props.slotOfDay.id,
                    nbPersons: parseInt(nbPersons),
                    nbCompanions: parseInt(nbCompanions),
                    refPersonLastname: refPersonLastname,
                    refPersonFirstname: refPersonFirstname,
                    refPersonEmail: refPersonEmail,
                    refPersonPhone: refPersonPhone,
                }
            })
        }

    }

    useEffect(() => {
        if (createReservationError) {
            addMessage({
                type: EnumMessageType.Error,
                message: createReservationError.message,
            })
        } else if (createReservationData) {
            if (createReservationData.createReservation.statusCode === 200) {
                if (currentUser?.legalStatus === EnumUserLegalStatus.enterprise) {
                    // B2B
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Réservation effectuée.",
                    })
                    dispatch(getSlots())
                    props.handleClose()
                } else {
                    // B2C
                    const newFormToken = createReservationData.createReservation.reservation.payment.payzenFormToken.payzenFormTokenAnswer.formToken
                    dispatch(setReservation(createReservationData.createReservation.reservation))
                    dispatch(setFormToken(newFormToken))
                    navigate(NAVIGATE_PAYMENT_PAGE)

                }

            } else {
                createReservationData.createReservation.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createReservationData, createReservationError])

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
            >
                <Flexbox direction='column'>
                    <DialogTitle color="primary">
                        Réserver l'atelier
                    </DialogTitle>
                    <DialogContent >
                        <Grid container spacing={1} sx={{ mt: 1, mb: 2 }} >
                            <Grid xs={6}>
                                <Typography sx={{
                                    fontSize: 18,
                                }}>
                                    Atelier
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography sx={{
                                    fontSize: 18,
                                }}>
                                    Cuisine créole réunionnaise
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography>
                                    Date
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography>
                                    {dayjs(props.slotOfDay.occurredAt).locale('fr').format(EnumDayjsFormat.day_month_name_year)}
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography>
                                    Horaire
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography>
                                    17h00 à 21h00
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography>
                                    Adresse
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography>
                                    30, rue Rodier, 97410 Saint-Pierre
                                </Typography>
                            </Grid>
                            <Divider sx={{
                                borderColor: theme.palette.primary.main,
                                width: "100%",
                                mt: 2,
                                mb: 1,
                            }} />
                            <Grid xs={5}>
                                &nbsp;
                            </Grid>
                            <Grid container xs={7}>
                                <Grid xs={3}>
                                    <Typography sx={{ fontSize: 14, }}>
                                        Quantité
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography sx={{ fontSize: 14, textAlign: "right" }}>
                                        Prix unitaire
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography sx={{ fontSize: 14, textAlign: "right" }}>
                                        Total TTC
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={5} sx={{ mt: 1 }}>
                                <Typography sx={{
                                    wordBreak: "break-word",
                                }}>
                                    Nombre de participants
                                </Typography>
                            </Grid>
                            <Grid container xs={7}>
                                <Grid xs={4}>
                                    <TextField variant='standard'
                                        type='number'
                                        autoFocus
                                        color="secondary"
                                        // onChange={(event) => { handleChangeNbPersons(parseInt(event.target.value)) }}
                                        onChange={(event) => { handleChangeNbPersons(event.target.value) }}
                                        value={nbPersons}
                                        error={errorOnNbPersons}
                                        helperText={errorOnNbPersons ? "Erreur de saisie." : ""}
                                    />
                                </Grid>
                                <Grid xs={4} sx={{ mt: 1 }}>
                                    <Typography sx={{ textAlign: "right" }}>
                                        {renderEuros(props.slotOfDay.price)}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} sx={{ mt: 1 }}>
                                    <Typography sx={{ textAlign: "right" }}>
                                        {renderEuros(calculatedPrice)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={5} sx={{ mt: 2 }}>
                                <Typography sx={{
                                    wordBreak: "break-word",
                                }}>
                                    Nombre d'accompagnants
                                </Typography>
                            </Grid>
                            <Grid container xs={7} sx={{ mt: 1 }}>
                                <Grid xs={4}>
                                    <TextField variant='standard'
                                        type='number'
                                        color="secondary"
                                        onChange={(event) => { handleChangeNbCompanions(event.target.value) }}
                                        value={nbCompanions}
                                        error={errorOnNbCompanions}
                                        helperText={errorOnNbCompanions ? "Erreur de saisie." : ""}
                                    />
                                </Grid>
                                <Grid xs={4} sx={{ mt: 1 }}>
                                    <Typography sx={{ textAlign: "right" }}>
                                        {renderEuros(props.slotOfDay.companionPrice)}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} sx={{ mt: 1 }}>
                                    <Typography sx={{ textAlign: "right" }}>
                                        {renderEuros(calculatedCompanionPrice)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid xs={5} sx={{ mt: 1 }}>
                                <Typography>
                                    &nbsp;
                                </Typography>
                            </Grid>
                            <Grid container xs={7}>
                                <Grid xs={4}>
                                    &nbsp;
                                </Grid>
                                <Grid xs={4} sx={{ mt: 1 }}>
                                    <Typography sx={{ textAlign: "right", fontSize: 16, fontWeight: 600 }}>
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid xs={4} sx={{ mt: 1 }}>
                                    <Typography sx={{ textAlign: "right", fontSize: 16, fontWeight: 600 }}>
                                        {renderEuros(calculatedPrice + calculatedCompanionPrice)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                currentUser?.legalStatus === EnumUserLegalStatus.enterprise && <>
                                    <Divider sx={{
                                        borderColor: theme.palette.primary.main,
                                        width: "100%",
                                        mt: 2,
                                        mb: 1,
                                    }} />
                                    <Grid xs={5} sx={{ mt: 2 }}>
                                        <Typography sx={{
                                            wordBreak: "break-word",
                                        }}>
                                            Client principal:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={7}>
                                        <TextField variant='standard'
                                            label="Nom"
                                            required
                                            color="secondary"
                                            onChange={(event) => { setRefPersonLastname(event.target.value) }}
                                            value={refPersonLastname}
                                            error={errorOnRefPersonLastname}
                                            helperText={errorOnRefPersonLastname ? "Erreur de saisie." : ""}
                                        />
                                    </Grid>
                                    <Grid xs={5} sx={{ mt: 1 }}>
                                        <Typography>
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid xs={7}>
                                        <TextField variant='standard'
                                            label="Prénom"
                                            required
                                            color="secondary"
                                            onChange={(event) => { setRefPersonFirstname(event.target.value) }}
                                            value={refPersonFirstname}
                                            error={errorOnRefPersonFirstname}
                                            helperText={errorOnRefPersonFirstname ? "Erreur de saisie." : ""}
                                        />
                                    </Grid>
                                    <Grid xs={5} sx={{ mt: 1 }}>
                                        <Typography>
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid xs={7}>
                                        <TextField variant='standard'
                                            label="Email"
                                            color="secondary"
                                            onChange={(event) => { setRefPersonEmail(event.target.value) }}
                                            value={refPersonEmail}
                                        />
                                    </Grid>
                                    <Grid xs={5} sx={{ mt: 1 }}>
                                        <Typography>
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid xs={7}>
                                        <TextField variant='standard'
                                            label="Téléphone"
                                            required
                                            color="secondary"
                                            onChange={(event) => { setRefPersonPhone(event.target.value) }}
                                            value={refPersonPhone}
                                            error={errorOnRefPersonPhone}
                                            helperText={errorOnRefPersonPhone ? "Erreur de saisie." : ""}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button onClick={handleConfirm} variant="outlined" color="secondary">Réserver</Button>
                    </DialogActions>
                </Flexbox>
            </Dialog >
        </>
    )
}
