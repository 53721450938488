import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { FC, useState } from 'react'
import { LockOutlined, LockOpenOutlined } from '@mui/icons-material';

interface IProps {
    open: boolean;
    title: string;
    content: string;
    handleClose: () => void;
    handleConfirm: () => void;
    danger?: boolean;
    deleteLabel?: string

}

export const DeleteConfirmDialog: FC<IProps> = (props) => {
    const [disabledConfirm, setDisabledConfirm] = useState(props.danger);

    const cleanDatas = () => {
        setDisabledConfirm(props.danger)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const handleConfirm = () => {
        cleanDatas()
        props.handleConfirm()
    }

    return (
        <div className="DeleteConfirmDialog">
            <Dialog
                open={props.open}
                onClose={handleClose}
            >
                <DialogTitle color="error">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color="error">
                        {props.content}
                        {
                            props.danger &&
                            <Box sx={{ mt: 2 }}>
                                <Divider />
                                <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                                    <Tooltip title={disabledConfirm ? "Déverrouiller" : "Verrouiller"}>
                                        <IconButton onClick={() => { setDisabledConfirm(!disabledConfirm) }} color={disabledConfirm ? "primary" : "error"}>
                                            {
                                                disabledConfirm ? <LockOutlined /> : <LockOpenOutlined />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                    <Typography sx={{ width: "100%", px: 2 }} color="primary">
                                        Attention: Cette opération est dangereuse !
                                        <br />
                                        Déverrouillez le cadenas pour continuer.
                                    </Typography>
                                </Box>
                            </Box>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                    <Button onClick={handleConfirm} disabled={disabledConfirm} variant="contained" color="error">
                        {props.deleteLabel || "Supprimer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
