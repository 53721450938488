import { configureStore } from "@reduxjs/toolkit"
import globalReducer from "./features/global/globalSlice"
import messagesReducer from "./features/global/messagesSlice"
import apolloReducer from "./features/global/apolloSlice"
import currentUserReducer from "./features/users/currentUserSlice"
import adminReducer from "./features/admin/adminSlice"
import paymentReducer from "./features/payments/paymentSlice"
import usersReducer from "./features/users/usersSlice"


export const store = configureStore({
    reducer: {
        globalReducer,
        messagesReducer,
        apolloReducer,
        currentUserReducer,
        adminReducer,
        paymentReducer,
        usersReducer,
    }
})

export type IRootState = ReturnType<typeof store.getState>

