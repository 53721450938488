import { FC } from 'react'
import { Flexbox } from '../../components/utils/Flexbox'
import { Layout } from "../Layout"
import { DescriptionSection } from '../../sections/DescriptionSection'
import { MenuSection } from '../../sections/MenuSection'
import { WorkshopSection } from '../../sections/WorkshopSection'
import { EngagementsSection } from '../../sections/EngagementsSection'
import { PrivatizeSection } from '../../sections/PrivatizeSection'
import { Footer } from '../../sections/Footer'
import { MazenieneLogo } from '../../components/MazenieneLogo'
import { EnumMazenieneLogoMode } from '../../enums'
import { Button } from '@mui/material'
import { AdminLayout } from './AdminLayout'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_ADMIN_SLOTS_PAGE, NAVIGATE_ADMIN_USERS_PAGE } from '../../utils/Navigation'

interface IProps {
}

export const AdminPage: FC<IProps> = (props) => {

  const navigate = useNavigate()


  return (
    <div className="AdminPage">
      <AdminLayout>
        <Flexbox direction='column'>
          <MazenieneLogo mode={EnumMazenieneLogoMode.standard} sx={{ width: "100px", mt: 4 }} />
          <Flexbox direction='column' sx={{
            justifyContent: "space-around",
            mt: 2,
          }}
          >
            <Button variant='text' color='primary' onClick={() => navigate(NAVIGATE_ADMIN_USERS_PAGE)}>
              Utilisateurs
            </Button>
            <Button variant='text' color='primary' onClick={() => navigate(NAVIGATE_ADMIN_SLOTS_PAGE)}>
              Créneaux atelier cuisine
            </Button>
          </Flexbox>
        </Flexbox>
      </AdminLayout>
    </div >
  )
}
