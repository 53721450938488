import { FC, useEffect } from 'react';
import { EnumDevice, EnumUserLegalStatus } from '../../enums';
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice';
import MenuItem from '@mui/material/MenuItem';
import { Link, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';

import { NAVIGATE_MY_RESERVATIONS_PAGE, NAVIGATE_ROOT, NAVIGATE_WORKSHOP_PAGE, isActivePath } from '../../utils/Navigation';


interface IPage {
    title: string
    path: string
}


interface IProps {
    device: EnumDevice,
    handleCloseNavMenu: () => void
    handleButtonNavMenu: (page: IPage) => void
}

export const MenuList: FC<IProps> = (props) => {
    const theme = useTheme()
    const location = useLocation()
    const currentUser = useGetCurrentUser()

    const bToCPages: IPage[] = [
        {
            title: 'Nos engagements',
            path: NAVIGATE_ROOT,
        },
        {
            title: 'Atelier de cuisine',
            path: NAVIGATE_WORKSHOP_PAGE,
        },
        {
            title: 'Menu',
            path: NAVIGATE_ROOT,
        },
        {
            title: 'Réserver votre table',
            path: NAVIGATE_ROOT,
        },
    ];

    const bToBPages: IPage[] = [
        {
            title: 'Atelier de cuisine',
            path: NAVIGATE_WORKSHOP_PAGE,
        },
        {
            title: 'Mes réservations',
            path: NAVIGATE_MY_RESERVATIONS_PAGE,
        },
    ];

    let pages = (currentUser && currentUser.legalStatus === EnumUserLegalStatus.enterprise) ? bToBPages : bToCPages

    useEffect(() => {
        if (currentUser && currentUser.legalStatus === EnumUserLegalStatus.enterprise) {
            pages = bToBPages
        } else {
            pages = bToCPages
        }
    }, [currentUser])


    return (
        <>
            {/* Mobile */}
            {
                props.device === EnumDevice.mobile && pages.map((page, index) => (
                    <MenuItem key={index} onClick={props.handleCloseNavMenu}
                        sx={{
                            backgroundColor: theme.palette.gris3.main,
                        }}
                    >
                        <Link sx={{
                            textDecoration: "none",
                            color: theme.palette.tertiary.main,
                            ":hover": {
                                color: theme.palette.gris2.main,
                                backgroundColor: "inherit",
                            }
                        }}
                            onClick={() => props.handleButtonNavMenu(page)} className={`${isActivePath(page.path, location.pathname) ? "active" : ""}`}>
                            {page.title}
                        </Link>
                    </MenuItem>
                ))
            }

            {/* Desktop */}
            {
                props.device === EnumDevice.desktop && pages.map((page, index) => (
                    <Button
                        key={index}
                        onClick={() => props.handleButtonNavMenu(page)}
                        sx={{
                            my: 2,
                            fontSize: "18px",
                            fontWeight: page.title === 'Accueil' ? "bold" : "normal",
                            color: theme.palette.tertiary.main,
                            ":hover": {
                                // color: theme.palette.secondary.main,
                                color: theme.palette.gris2.main,
                                backgroundColor: "inherit",
                            }
                        }}
                    >
                        {page.title}
                    </Button>
                ))
            }
        </>
    )
}


