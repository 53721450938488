import { gql } from "@apollo/client"


export const GQL_FRAGMENT_USER = gql`
  fragment User on User {
    id
    legalStatus
    enterpriseName
    email
    firstname
    lastname
    phone
    userType
    admin
    invitationToken
    agencyDirector
  }
`

export const GQL_FRAGMENT_SLOT = gql`
  fragment Slot on Slot {
    id
    occurredAt
    nbPlaces
    price
    companionPrice
    availablePlaces
  }
`

export const GQL_FRAGMENT_RESERVATION = gql`
  fragment Reservation on Reservation {
    id
    nbPersons
    nbCompanions
    orderNumber
    refPersonLastname
    refPersonFirstname
    refPersonEmail
    refPersonPhone
  }
`

export const GQL_FRAGMENT_PAYMENT = gql`
  fragment Payment on Payment {
    id
    paymentStatus
  }
`



