import { Box, Button, Link, TextField, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { EnumMazenieneLogoMode, EnumMessageType } from '../enums'
import { useMutation } from '@apollo/client'
import { GQL_MUTATION_LOGIN, GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL } from '../graphql/Mutations'
import { addMessage } from "../components/messages/Message"
import { useDispatch, useSelector } from 'react-redux'
import { setJwt } from '../redux/features/global/apolloSlice'
import { useNavigate } from 'react-router'
import { IRootState } from '../redux/store'
import { NAVIGATE_ROOT } from '../utils/Navigation'
import { MazenieneLogo } from '../components/MazenieneLogo'
import { setCurrentUser } from '../redux/features/users/currentUserSlice'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from './Layout'

interface IProps {
}

export const LoginPage: FC<IProps> = (props) => {
  const hasJwt = useSelector((state: IRootState) => state.apolloReducer.hasJwt)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")


  useEffect(() => {
    if (hasJwt) {
      // Il y a un JWT, on redirect sur home..
      navigate(`${NAVIGATE_ROOT}`)
    }
  }, [])


  const [login, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(GQL_MUTATION_LOGIN)
  const [sendForgottenPasswordMail, { data: sendForgottenPasswordMailData, loading: sendForgottenPasswordMailLoading, error: sendForgottenPasswordMailError }] = useMutation(GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL)

  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (loginError) {
      addMessage({
        location: "Login",
        type: EnumMessageType.Error,
        message: loginError.message,
      })
    } else if (loginData) {
      if (loginData.login.statusCode === 200) {
        dispatch(setJwt(loginData.login.authToken))
        dispatch(setCurrentUser(loginData.login.user))
        addMessage({
          type: EnumMessageType.Success,
          message: "Connexion réussie.",
        })
        // Refresh page: (redirect sur home au reload..)
        // navigate(0)
        // navigate(NAVIGATE_ADMIN_PAGE)
        navigate(-1)
      } else {
        loginData.login.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [loginData, loginError])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login({
      variables: {
        email: email,
        password: password,
      }
    })
  };

  const handleForgottenPassword = () => {
    if (email === undefined || email === "") {
      addMessage({
        type: EnumMessageType.Error,
        message: "Merci de saisir votre email.",
      }
      )
    } else {
      sendForgottenPasswordMail({
        variables: {
          email: email,
        }
      })
    }
  }

  useEffect(() => {
    if (sendForgottenPasswordMailError) {
      addMessage({
        location: "LoginPage",
        type: EnumMessageType.Error,
        message: sendForgottenPasswordMailError.message,
      })
    } else if (sendForgottenPasswordMailData) {
      if (sendForgottenPasswordMailData.sendForgottenPasswordMail.statusCode === 200) {
        addMessage({
          type: EnumMessageType.Success,
          message: "Un mail de changement de mot de passe vous a été envoyé."
        })
      } else {
        sendForgottenPasswordMailData.sendForgottenPasswordMail.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [sendForgottenPasswordMailData, sendForgottenPasswordMailError])

  return (
    <div className="LoginPage">
      <Layout>
        <Flexbox direction='column' sx={{ mx: 4, mt: 4 }}>
          <Flexbox direction='column' sx={{ maxWidth: "600px" }}>

            <MazenieneLogo mode={EnumMazenieneLogoMode.standard} sx={{ width: "200px" }} />
            <Typography color="text.primary" sx={{ fontFamily: theme.typography.secondary, fontSize: 24 }}>
              Connexion
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                color="primary"
                onChange={(event) => { setEmail(event.target.value) }}
                value={email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => { setPassword(event.target.value) }}
                value={password}
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}
              >
                Se connecter
              </Button>
              <Flexbox>
                <Link href='#' onClick={handleForgottenPassword} variant="body2">
                  Mot de passe oublié ?
                </Link>
              </Flexbox>
            </Box>
          </Flexbox>
        </Flexbox>
      </Layout>
    </div >
  )
}
