import { FC } from 'react'
import { Flexbox } from '../../components/utils/Flexbox'
import { AdminLayout } from './AdminLayout'
import { MainTitle } from '../../components/MainTitle'
import { ListUsers } from '../../components/users/ListUsers'

interface IProps {
}

export const AdminUsersPage: FC<IProps> = (props) => {

  return (
    <div className="AdminUsersPage">
      <AdminLayout>
        <Flexbox direction='column'>
          <MainTitle title="Utilisateurs" />
          <ListUsers />
        </Flexbox>
      </AdminLayout>
    </div >
  )
}
