import { FC } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Flexbox } from '../components/utils/Flexbox';
import { useNavigate } from 'react-router-dom';
import { NAVIGATE_WORKSHOP_PAGE } from '../utils/Navigation';

interface IProps { }

export const WorkshopSection: FC<IProps> = (props) => {

    const theme = useTheme()
    const navigate = useNavigate()

    // return (
    //     <Box sx={{ width: "100%", py: 4 }}>
    //         <Flexbox
    //             sx={{
    //                 flexDirection: { xs: "column", sm: "row" },
    //                 justifyContent: { xs: "center", sm: "space-between" },
    //                 alignItems: { xs: "center", sm: "flex-start" },
    //                 mx: { xs: 2, sm: 12 },
    //             }}
    //         >

    //             <Flexbox direction='column'
    //                 sx={{
    //                     alignItems: { xs: "center", sm: "flex-start" },
    //                     px: 2,
    //                     width: "100%",
    //                 }}
    //             >
    //                 <Typography
    //                     sx={{
    //                         fontSize: { xs: 18, sm: 28 },
    //                         mb: 2,
    //                     }}
    //                 >
    //                     Réservez nos ateliers de cuisine !
    //                 </Typography>
    //                 <Typography>
    //                     Texte descriptif sur le déroulé de l’atelier cuisine, le tarif, les horaires, ce que la personne pourra y trouver.
    //                     <br />
    //                     Etc.
    //                 </Typography>
    //                 <Flexbox sx={{ mt: 8, width: "100%", justifyContent: "flex-end" }}>
    //                     <Button variant='outlined' onClick={() => navigate(NAVIGATE_WORKSHOP_PAGE)} sx={{ color: theme.palette.primary.main }}>
    //                         Réservez votre atelier
    //                     </Button>
    //                 </Flexbox>

    //             </Flexbox>
    //             <Box
    //                 component="img"
    //                 src={`${process.env.PUBLIC_URL}/images/accueil/draft_03.png`}
    //                 sx={{
    //                     width: { xs: "150px", sm: "300px" },
    //                     mx: 2,
    //                 }}
    //             />

    //         </Flexbox>
    //     </Box>
    // )
    return (
        <>
            {/* Mobile */}
            <Box sx={{
                display: { xs: "block", sm: "none" },
                mx: 4,
                my: 2,
                py: 4,
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.gris3.main,
                boxShadow: `3px 2px 2px ${theme.palette.gris3.main}`,
            }}>
                <Flexbox
                    direction='column'
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        mx: 2,
                    }}
                >
                    <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/images/accueil/draft_03.png`}
                        sx={{
                            width: "150px",
                            mb: 2,
                        }}
                    />
                    <Flexbox direction='column'
                        sx={{
                            alignItems: "center",
                            px: 2,
                            width: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                mb: 2,
                                textAlign: "center",
                            }}
                        >
                            Réservez nos ateliers de cuisine !
                        </Typography>
                        <Typography>
                            Texte descriptif sur le déroulé de l’atelier cuisine, le tarif, les horaires, ce que la personne pourra y trouver.
                            <br />
                            Etc.
                        </Typography>
                        <Flexbox sx={{ mt: 8, width: "100%", justifyContent: "flex-end" }}>
                            <Button variant='outlined' onClick={() => navigate(NAVIGATE_WORKSHOP_PAGE)} sx={{ color: theme.palette.primary.main }}>
                                Réservez votre atelier
                            </Button>
                        </Flexbox>

                    </Flexbox>


                </Flexbox>
            </Box>

            {/* Desktop */}
            <Box sx={{
                display: { xs: "none", sm: "block" },
                width: "100%",
                py: 4,
            }}>
                <Flexbox
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        mx: 12,
                    }}
                >

                    <Flexbox direction='column'
                        sx={{
                            alignItems: "flex-start",
                            px: 2,
                            width: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 28,
                                mb: 2,
                            }}
                        >
                            Réservez nos ateliers de cuisine !
                        </Typography>
                        <Typography>
                            Texte descriptif sur le déroulé de l’atelier cuisine, le tarif, les horaires, ce que la personne pourra y trouver.
                            <br />
                            Etc.
                        </Typography>
                        <Flexbox sx={{ mt: 8, width: "100%", justifyContent: "flex-end" }}>
                            <Button variant='outlined' onClick={() => navigate(NAVIGATE_WORKSHOP_PAGE)} sx={{ color: theme.palette.primary.main }}>
                                Réservez votre atelier
                            </Button>
                        </Flexbox>

                    </Flexbox>
                    <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/images/accueil/draft_03.png`}
                        sx={{
                            width: "300px",
                            mx: 2,
                        }}
                    />

                </Flexbox>
            </Box>
        </>
    )
}


