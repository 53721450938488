import { FC } from 'react'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from "./Layout"
import { DescriptionSection } from '../sections/DescriptionSection'
import { MenuSection } from '../sections/MenuSection'
import { WorkshopSection } from '../sections/WorkshopSection'
import { EngagementsSection } from '../sections/EngagementsSection'
import { PrivatizeSection } from '../sections/PrivatizeSection'
import { Box } from '@mui/material'
import { EnumBuildMode, EnumDevice } from '../enums'

interface IProps {
}

export const Home: FC<IProps> = (props) => {



  return (
    <div className="Home">
      <Layout>
        <Flexbox direction='column'>
          <DescriptionSection buildMode={EnumBuildMode.building} />
          <MenuSection />
          <WorkshopSection />
          <EngagementsSection />
          <PrivatizeSection />
        </Flexbox>
      </Layout>
    </div >
  )
}
