import { FC, ReactNode } from 'react';
import { Box, SxProps } from "@mui/material";

interface IProps {
    direction?: string,
    children: ReactNode,
    sx?: SxProps,
    onClick?: () => void;
    className?: string,
}

export const Flexbox: FC<IProps> = (props) => {
    return (
        <>
            {
                (props.direction === undefined || props.direction === "row") &&
                <Box
                    sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", ...props.sx }}
                    onClick={props.onClick}
                    className={props.className}
                >
                    {props.children}
                </Box>
            }
            {
                props.direction === "column" &&
                <Box
                    sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", ...props.sx }}
                    onClick={props.onClick}
                    className={props.className}
                >
                    {props.children}
                </Box>
            }
        </>
    )
}


