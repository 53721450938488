import { FC, useEffect, useState } from 'react';
import { Typography, useTheme } from "@mui/material";
import { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { capitalizeString, inSameMonth, renderEuros } from '../utils/helpers';
import { Flexbox } from './utils/Flexbox';
import { EnumDayjsFormat, EnumDevice, EnumMessageType, EnumSlotParametersMode, EnumWorkshopPlanningMode } from '../enums';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_CREATE_SLOT, GQL_MUTATION_DELETE_SLOT } from '../graphql/Mutations';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../redux/store';
import { addMessage } from './messages/Message';
import { useGetSlotByDate, getSlots } from '../redux/features/global/globalSlice';
import { ISlot, IUser } from '../interfaces';
import { ConnectionNeededDialog } from './dialogs/ConnectionNeededDialog';
import { CreateReservationDialog } from './dialogs/CreateReservationDialog';
import { SlotInformationsDialog } from './dialogs/SlotInformationsDialog';

interface IProps {
    datePlanningBegin: Dayjs,
    datePlanning: Dayjs,
    indexLineOfMonth: number,
    indexDayOfWeek: number,
    dayWidth: string,
    mode: EnumWorkshopPlanningMode,
    device: EnumDevice,
}

export const WorkshopPlanningDay: FC<IProps> = (props) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const coefficient = props.indexLineOfMonth * 7 + props.indexDayOfWeek
    const currentDate = props.datePlanningBegin.add(coefficient, 'day')
    const isInCurrentMonth = inSameMonth(props.datePlanning, currentDate)

    const nbPlaces: number = useSelector((state: IRootState) => state.adminReducer.slotAvailableQuantity)
    const price: number = useSelector((state: IRootState) => state.adminReducer.slotPrice)
    const companionPrice: number = useSelector((state: IRootState) => state.adminReducer.slotCompanionPrice)
    const slotParametersMode: EnumSlotParametersMode = useSelector((state: IRootState) => state.adminReducer.slotParametersMode)

    const slotOfDay: ISlot | undefined = useGetSlotByDate(currentDate)

    const currentUser: (undefined | IUser) = useSelector((state: IRootState) => state.currentUserReducer.user)
    const [showConnectionNeededDialog, setShowConnectionNeededDialog] = useState(false)
    const [showCreateReservationDialog, setShowCreateReservationDialog] = useState(false)
    const [showSlotInformationsDialog, setShowSlotInformationsDialog] = useState(false)

    const [createSlot, { data: createSlotData, error: createSlotError }] = useMutation(GQL_MUTATION_CREATE_SLOT)
    // const [createReservation, { data: createReservationData, loading: createReservationLoading, error: createReservationError }] = useMutation(GQL_MUTATION_CREATE_RESERVATION)
    const [deleteSlot, { data: deleteSlotData, error: deleteSlotError }] = useMutation(GQL_MUTATION_DELETE_SLOT)




    const clickable = () => {
        if (isInCurrentMonth) {
            if (props.mode === EnumWorkshopPlanningMode.admin) {
                // En mode création, il ne doit pas y avoir de slotDay
                if (slotParametersMode === EnumSlotParametersMode.creation && slotOfDay) {
                    return false
                }

                // En mode information, il doit y avoir un slotDay
                if (slotParametersMode === EnumSlotParametersMode.information && !slotOfDay) {
                    return false
                }

                // En mode suppression, il doit y avoir un slotDay mais pas de reservation dessus.
                if (slotParametersMode === EnumSlotParametersMode.suppression &&
                    (!slotOfDay || slotOfDay.nbPlaces !== slotOfDay.availablePlaces)
                ) {
                    return false
                }
                // Sinon c'est clickable
                return true
            } else if (
                props.mode === EnumWorkshopPlanningMode.standard &&
                slotOfDay &&
                slotOfDay.availablePlaces > 0
            ) {
                return true
            }
        }
        return false
    }

    const getHoverColor = () => {
        if (clickable()) {
            if (props.mode === EnumWorkshopPlanningMode.admin) {
                if (slotParametersMode === EnumSlotParametersMode.creation) {
                    return theme.palette.success.main
                } if (slotParametersMode === EnumSlotParametersMode.information) {
                    return theme.palette.info.main
                } if (slotParametersMode === EnumSlotParametersMode.suppression) {
                    return theme.palette.error.main
                } else {
                    addMessage({
                        location: "WorkshopPlanningDay",
                        type: EnumMessageType.Error,
                        message: "mode de slot parameters inconnu."
                    })
                }
            }
            return theme.palette.success.main
        }
        return "none"
    }

    const hoverColor = getHoverColor()

    const triggerCreateSlot = () => {
        createSlot({
            variables: {
                occurredAt: currentDate,
                nbPlaces,
                price,
                companionPrice,
            }
        })
    }
    useEffect(() => {
        if (createSlotError) {
            addMessage({
                type: EnumMessageType.Error,
                message: createSlotError.message,
            })
        } else if (createSlotData) {
            if (createSlotData.createSlot.statusCode === 200) {
                dispatch(getSlots())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Créneau enregistré.",
                })
            } else {
                createSlotData.createSlot.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createSlotData, createSlotError])

    const triggerDeleteSlot = () => {
        if (slotOfDay) {
            deleteSlot({
                variables: {
                    slotId: slotOfDay.id,
                }
            })
        }
    }
    useEffect(() => {
        if (deleteSlotError) {
            addMessage({
                type: EnumMessageType.Error,
                message: deleteSlotError.message,
            })
        } else if (deleteSlotData) {
            if (deleteSlotData.deleteSlot.statusCode === 200) {
                dispatch(getSlots())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Créneau supprimé.",
                })
            } else {
                deleteSlotData.deleteSlot.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteSlotData, deleteSlotError])

    const handleClick = () => {
        if (clickable()) {
            if (props.mode === EnumWorkshopPlanningMode.admin) {
                // if (slotOfDay) {
                //     addMessage({
                //         type: EnumMessageType.Error,
                //         message: "Le créneau existe déjà.",
                //     })
                // } else {
                //     triggerCreateSlot()
                // }
                if (slotParametersMode === EnumSlotParametersMode.creation) {
                    triggerCreateSlot()
                } else if (slotParametersMode === EnumSlotParametersMode.information) {
                    setShowSlotInformationsDialog(true)
                } else if (slotParametersMode === EnumSlotParametersMode.suppression) {
                    triggerDeleteSlot()
                } else {
                    addMessage({
                        type: EnumMessageType.Error,
                        location: "WorkshopPlanningDay",
                        message: "handleClick ne reconnaît pas ce mode de slot parameters."
                    })
                }
            } else if (props.mode === EnumWorkshopPlanningMode.standard) {
                if (!currentUser) {
                    setShowConnectionNeededDialog(true)
                } else {
                    setShowCreateReservationDialog(true)
                }
            }
        }
    }

    const determineCaseBackgroundColor = () => {
        let res = theme.palette.gris1.main
        if (isInCurrentMonth) {
            // res = "white"
            res = theme.palette.gris3.main
            if (slotOfDay) {
                res = "white"
                if (props.device === EnumDevice.mobile) {
                    // Il y a un slot mais reste-t-il de la place ?
                    if (slotOfDay.availablePlaces > 0) {
                        res = theme.palette.success.light
                    } else {
                        res = theme.palette.error.light
                    }
                }
            }
        }
        return res
    }

    const determineCaseBackgroundColorTitle = () => {
        let res = theme.palette.gris2.main
        if (isInCurrentMonth) {
            res = theme.palette.gris1.main
            if (slotOfDay) {
                // Il y a un slot mais reste-t-il de la place ?
                if (slotOfDay.availablePlaces > 0) {
                    res = theme.palette.success.light
                } else {
                    res = theme.palette.error.light
                }
            }
        }
        return res
    }

    const caseBackgroundColorTitle = determineCaseBackgroundColorTitle()
    const caseBackgroundColor = determineCaseBackgroundColor()

    return (
        <>
            <Flexbox
                direction='column'
                onClick={handleClick}
                sx={{
                    width: props.dayWidth,
                    // border: "1px solid black",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: isInCurrentMonth ? theme.palette.primary.main : theme.palette.grey[400],
                    alignItems: "flex-start",
                    mx: props.device === EnumDevice.mobile ? .1 : .5,
                    mb: 2,
                    ":hover": {
                        cursor: clickable() ? "pointer" : "unset",
                        borderColor: hoverColor,
                        ">.WorkshopPlanningDay-title": {
                            backgroundColor: hoverColor,
                        }
                    }
                }}>
                <Flexbox
                    className="WorkshopPlanningDay-title"
                    sx={{
                        width: "100%",
                        // backgroundColor: isInCurrentMonth ? theme.palette.primary.light : theme.palette.grey[400],
                        backgroundColor: caseBackgroundColorTitle,
                        color: theme.palette.primary.contrastText,
                        justifyContent: "flex-start",
                    }}>
                    <Typography sx={{
                        mx: 1,
                    }}>
                        {capitalizeString(currentDate.format(EnumDayjsFormat.day_number))}
                    </Typography>
                </Flexbox>
                <Flexbox sx={{
                    width: "100%",
                    // backgroundColor: isInCurrentMonth ? "white" : theme.palette.background.default,
                    backgroundColor: caseBackgroundColor,
                    // py: 2,
                    py: props.device === EnumDevice.mobile ? .5 : 2,
                    minHeight: props.mode === EnumWorkshopPlanningMode.admin ? "100px" : "30px",
                }}>
                    {
                        slotOfDay &&
                        <Flexbox direction='column' sx={{
                            alignItems: "flex-start",
                        }}>
                            {
                                props.mode === EnumWorkshopPlanningMode.admin &&
                                <Typography sx={{
                                    color: theme.palette.primary.main,
                                }}>
                                    Places: {slotOfDay.nbPlaces}
                                    <br />
                                    {renderEuros(slotOfDay.price)} / {renderEuros(slotOfDay.companionPrice)}
                                </Typography>
                            }
                            {
                                slotOfDay.availablePlaces > 0 ?

                                    (
                                        props.device === EnumDevice.mobile ?
                                            <Flexbox direction='row' sx={{ alignItems: "center", gap: .5 }}>
                                                {/* <PilonIcon
                                                    primarycolor={theme.palette.success.main}
                                                    secondarycolor={theme.palette.success.dark}
                                                    sx={{
                                                        fontSize: 16,
                                                    }}
                                                /> */}
                                                <Typography sx={{
                                                    color: theme.palette.success.main,
                                                }}>
                                                    {slotOfDay.availablePlaces}
                                                </Typography>

                                            </Flexbox>
                                            :
                                            <Typography sx={{
                                                color: theme.palette.success.main,
                                            }}>
                                                {slotOfDay.availablePlaces} places disponibles
                                            </Typography>

                                    )

                                    :

                                    (
                                        props.device === EnumDevice.mobile ?
                                            // <PilonIcon
                                            //     primarycolor={theme.palette.error.light}
                                            //     secondarycolor={theme.palette.error.main}
                                            //     sx={{
                                            //         fontSize: 16,
                                            //     }}
                                            // />
                                            <Typography sx={{
                                                color: theme.palette.error.main,
                                            }}>
                                                {/* {slotOfDay.availablePlaces} */}
                                                &nbsp;
                                            </Typography>
                                            :
                                            <Typography sx={{
                                                color: theme.palette.error.main,
                                            }}>
                                                Complet
                                            </Typography>

                                    )
                            }
                            {
                                props.mode === EnumWorkshopPlanningMode.admin &&
                                slotOfDay.nbPlaces !== slotOfDay.availablePlaces &&
                                <Typography sx={{
                                    color: theme.palette.info.main,
                                }}>
                                    {slotOfDay.nbPlaces - slotOfDay.availablePlaces} places réservées
                                </Typography>
                            }
                        </Flexbox>
                    }
                </Flexbox>
            </Flexbox>




            <ConnectionNeededDialog
                open={showConnectionNeededDialog}
                handleClose={() => setShowConnectionNeededDialog(false)}
            />
            {
                slotOfDay &&
                <CreateReservationDialog
                    open={showCreateReservationDialog}
                    handleClose={() => setShowCreateReservationDialog(false)}
                    slotOfDay={slotOfDay}
                // handleConfirm={confirmCreateReservationDialog}
                />
            }
            {
                slotOfDay &&
                <SlotInformationsDialog
                    open={showSlotInformationsDialog}
                    handleClose={() => setShowSlotInformationsDialog(false)}
                    slotOfDay={slotOfDay}
                />
            }
        </>
    )
}


