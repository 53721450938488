

export enum EnumPilonLogoMode {
    standard = "standard",
    accueil = "accueil",
    accueilMobile = "accueilMobile",
}

export enum EnumMazenieneLogoMode {
    standard = "standard",
    accueil = "accueil",
    accueilMobile = "accueilMobile",
}

export enum EnumMessageType {
    Success = 1,
    Error,
    Info,
    Warning,
}

export enum EnumDayjsFormat {
    date = "DD/MM/YYYY",
    day_name_date = "dddd DD/MM/YYYY",
    date_hours_minutes = "DD/MM/YYYY HH:mm",
    date_hours_minutes_secondes = "DD/MM/YYYY HH:mm:ss",
    hours_minutes = "HH:mm",
    day_name_day_number = "dddd DD",
    day_number = "DD",
    day_name = "dddd",
    day_small = "ddd",
    month_name = "MMMM",
    month_name_year = "MMMM YYYY",
    day_month_name_year = "DD MMMM YYYY",
}

export enum EnumWorkshopPlanningMode {
    standard = "standard",
    admin = "admin",
}

export enum EnumSlotParametersMode {
    creation = "creation",
    information = "information",
    suppression = "suppression",
}

export enum EnumPaymentStatus {
    UNKNOWN = "UNKNOWN",
    PAID = "PAID",
    UNPAID = "UNPAID",
}

export enum EnumUserLegalStatus {
    particular = "particular",
    enterprise = "enterprise",
}

export enum EnumDevice {
    desktop = "desktop",
    mobile = "mobile",
}

export enum EnumBuildMode {
    standard = "standard",
    building = "building",
}


