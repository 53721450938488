
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr';

dayjs.extend(utc);

export const getBeginningOfTheWeek = (givenDate: Dayjs) => {
    // return givenDate.startOf('week').day(1).hour(12).minute(0).second(0)
    return givenDate.startOf('week').hour(12).minute(0).second(0)
}

export const getBeginningOfTheMonth = (givenDate: Dayjs) => {
    return givenDate.startOf('month').hour(12).minute(0).second(0)
}

export const getEndOfTheWeek = (givenDate: Dayjs) => {
    // return givenDate.endOf('week').day(1).hour(12).minute(0).second(0)
    return givenDate.endOf('week').hour(12).minute(0).second(0)
}

export const getEndOfTheMonth = (givenDate: Dayjs) => {
    return givenDate.endOf('month').hour(12).minute(0).second(0)
}

export const getNbDays = (startAt: Dayjs, endAt: Dayjs) => {
    // On met le +2 pour inclure les dates données.
    return endAt.diff(startAt, 'day') + 2
}

export const inSameMonth = (date1: Dayjs, date2: Dayjs) => {
    return date1.month() === date2.month() && date1.year() === date2.year();
}

export const truncateString = (p_string: string, size: number) => {
    if (p_string === undefined || p_string === null) {
        return ""
    }
    return p_string.length > size ? `${p_string.slice(0, size)}...` : p_string
}

export const capitalizeString = (p_string: string, allLetters: boolean = false) => {
    let res = ""
    if (p_string === undefined || p_string === null) {
        return res
    }
    if (allLetters) {
        res = p_string.toUpperCase()
    } else {
        res = p_string.charAt(0).toUpperCase() + p_string.slice(1);
    }
    return res
}

export const renderEuros = (p_amount: (string | number)) => {
    let amount = parseFloat(p_amount.toString())
    amount = Number((amount).toFixed(2))
    const splitted_amount = amount.toString().split('.')
    let string_amount = splitted_amount[0]
    if (splitted_amount.length > 1) {
        string_amount += "," + splitted_amount[1]
        if (splitted_amount[1].length === 1) {
            string_amount += "0"
        }
    } else {
        string_amount += ",00"
    }
    return `${string_amount} €`
}

export const matchStrings = (p_query: (string | undefined), p_string_to_scan: (string | undefined)) => {
    const query = p_query || ""
    const string_to_scan = p_string_to_scan || ""
    return string_to_scan.toLowerCase().includes(query.toLowerCase())
}

export const matchDateBefore = (p_date_from: (string | undefined), p_date_to_compare: Dayjs) => {
    if (p_date_from === undefined || p_date_from === "") {
        return true
    }
    // On transforme en "YYYY-MM-DD" car impossible de faire comprendre à Dayjs le format DD/MM/YYYY'..
    // const date_from = dayjs(p_date_from.split('/').reverse().join('-'), { format: 'YYYY-MM-DD', locale: 'fr' });
    const date_from = dayjs.utc(p_date_from.split('/').reverse().join('-'));
    return dayjs.utc(date_from).startOf("day").isBefore(p_date_to_compare)

}

export const matchDateAfter = (p_date_to: (string | undefined), p_date_to_compare: Dayjs) => {
    if (p_date_to === undefined || p_date_to === "") {
        return true
    }
    // On transforme en "YYYY-MM-DD" car impossible de faire comprendre à Dayjs le format DD/MM/YYYY'..
    // const date_to = dayjs(p_date_to.split('/').reverse().join('-'), { format: 'YYYY-MM-DD', locale: 'fr' });
    const date_to = dayjs.utc(p_date_to.split('/').reverse().join('-'));
    return dayjs.utc(date_to).endOf("day").isAfter(p_date_to_compare)

}

export const hexToRgba = (hex: string, alpha: string) => {
    // Supprimer le caractère '#' du début, s'il est présent
    hex = hex.replace(/^#/, '');

    // Décomposer la chaîne hexadécimale en composants R, G, B
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Retourner les composants dans une chaîne "rgba"
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

