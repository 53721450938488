import { FC, useEffect } from 'react';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../redux/store';
import { capitalizeString, getBeginningOfTheMonth, getBeginningOfTheWeek, getEndOfTheMonth, getEndOfTheWeek, getNbDays } from '../utils/helpers';
import { Flexbox } from './utils/Flexbox';
import { EnumDayjsFormat, EnumDevice, EnumWorkshopPlanningMode } from '../enums';
import { getSlots, setDatePlanningAction } from '../redux/features/global/globalSlice';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { WorkshopPlanningDay } from './WorkshopPlanningDay';

interface IProps {
    mode: EnumWorkshopPlanningMode,
    device: EnumDevice,
}

export const WorkshopPlanning: FC<IProps> = (props) => {

    const datePlanning: Dayjs = dayjs(useSelector((state: IRootState) => state.globalReducer.datePlanning)).locale('fr')
    // const datePlanningBegin: Dayjs = getBeginningOfTheMonth(datePlanning)
    const datePlanningBegin: Dayjs = getBeginningOfTheWeek(getBeginningOfTheMonth(datePlanning))
    const datePlanningEnd: Dayjs = getEndOfTheWeek(getEndOfTheMonth(datePlanning))
    // const dayWidth = "100px"
    // const dayWidth = "160px"

    // const maxPlanningWidth = {
    //     xs: "350px",
    //     sm: "1200px",
    // }

    const dayWidth = () => {
        return props.device === EnumDevice.mobile ? "50px" : "160px"
    }



    const theme = useTheme()
    const dispatch = useDispatch()

    const previousDatePlanning = () => {
        handleDatePlanning(datePlanning.subtract(1, 'month'))
    }

    const nextDatePlanning = () => {
        handleDatePlanning(datePlanning.add(1, 'month'))
    }

    const handleDatePlanning = (newDate: Dayjs | null) => {
        if (newDate) {
            dispatch(setDatePlanningAction(newDate.format()))
        }
    }

    useEffect(() => {
        dispatch(getSlots())
    }, [])


    return (
        <Box sx={{
            width: "100%",
            py: 4,
            // bgcolor: "red",
        }}>
            <Flexbox direction='column' sx={{
                // bgcolor: "green",
            }}>
                <Flexbox sx={{ alignItems: "flex-start" }}>
                    <IconButton onClick={() => previousDatePlanning()} sx={{ color: theme.palette.primary.main }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography
                        sx={{
                            color: theme.palette.primary.main,
                            fontSize: 24,
                            mx: 4,
                            mb: 2,
                        }}>
                        {capitalizeString(datePlanning.format(EnumDayjsFormat.month_name_year))}

                    </Typography>
                    <IconButton onClick={() => nextDatePlanning()} sx={{ color: theme.palette.primary.main }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Flexbox>
                <Flexbox direction='column'
                    sx={{
                        // maxWidth: maxPlanningWidth.xs,
                        // width: { xs: maxPlanningWidth.xs, sm: maxPlanningWidth.sm },
                        justifyContent: "flex-start",
                        // alignItems: "flex-start",
                        alignItems: "center",
                        // bgcolor: "red",
                        // overflowX: "scroll",
                    }}>
                    <Flexbox sx={{ mb: 1, }}>
                        {Array.from({ length: 7 }, (_, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    width: dayWidth(),
                                    border: "1px solid transparent",
                                    textAlign: "center",
                                    // mx: .5,
                                    mx: props.device === EnumDevice.mobile ? .1 : .5,
                                }}>
                                {/* {capitalizeString(datePlanningBegin.add(index, 'day').format(EnumDayjsFormat.day_name))} */}
                                {props.device === EnumDevice.mobile ?
                                    capitalizeString(datePlanningBegin.add(index, 'day').format(EnumDayjsFormat.day_small))
                                    :
                                    capitalizeString(datePlanningBegin.add(index, 'day').format(EnumDayjsFormat.day_name))
                                }
                            </Typography>
                        ))}
                    </Flexbox>
                    <Flexbox direction='column'>
                        {Array.from({ length: getNbDays(datePlanningBegin, datePlanningEnd) / 7 }, (_, indexLine) => (
                            <Flexbox key={`${indexLine}`}>
                                {
                                    Array.from({ length: 7 }, (_, indexDayOfWeek) => (
                                        <WorkshopPlanningDay
                                            key={`${indexLine} - ${indexDayOfWeek}`}
                                            datePlanningBegin={datePlanningBegin}
                                            datePlanning={datePlanning}
                                            indexLineOfMonth={indexLine}
                                            indexDayOfWeek={indexDayOfWeek}
                                            dayWidth={dayWidth()}
                                            mode={props.mode}
                                            device={props.device}
                                        />
                                    ))
                                }
                            </Flexbox>
                        ))}
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Box>
    )
}


