import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';
import { Flexbox } from '../utils/Flexbox';
import { useNavigate } from 'react-router-dom';
import { NAVIGATE_LOGIN_PAGE, NAVIGATE_SIGNUP_PAGE } from '../../utils/Navigation';

interface IProps {
    open: boolean,
    handleClose: () => void,
}

export const ConnectionNeededDialog: FC<IProps> = (props) => {
    const navigate = useNavigate()
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
            >
                <Flexbox direction='column' >
                    <DialogTitle color="primary">
                        Connection nécessaire
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Pour réserver un atelier cuisine, merci de vous connecter.
                            <br />
                            Si vous n'avez pas encore de compte, merci de vous inscrire.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => navigate(NAVIGATE_LOGIN_PAGE)} variant="outlined" color="primary">Se connecter</Button>
                        <Button onClick={() => navigate(NAVIGATE_SIGNUP_PAGE)} variant="outlined" color="primary">S'inscrire</Button>
                    </DialogActions>
                </Flexbox>
            </Dialog>
        </>
    )
}
