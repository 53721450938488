import { FC } from 'react';
import { Typography, useTheme } from "@mui/material";
import { IReservation, IUser } from '../../interfaces';
import { SxProps } from "@mui/material";
import { Flexbox } from '../utils/Flexbox';
import { EnumDayjsFormat, EnumPaymentStatus, EnumUserLegalStatus } from '../../enums';
import { CopyToClipboard } from '../utils/CopyToClipboard';
import { NAVIGATE_INVIATION_PAGE } from '../../utils/Navigation';
import BusinessIcon from '@mui/icons-material/Business';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

interface IProps {
    index: number,
    reservation: IReservation,
    sx?: SxProps,
}

export const MyReservationItem: FC<IProps> = (props) => {
    const theme = useTheme()

    const displayReferenteInfos = () => {
        let res = `${props.reservation.refPersonFirstname} ${props.reservation.refPersonLastname}`
        if (props.reservation.refPersonEmail) {
            res = `${res} (${props.reservation.refPersonEmail})`
        }
        return res
    }

    return (
        <>
            <Flexbox sx={{
                width: "calc(100% - 32px)",
                justifyContent: "space-between",
                mx: 4,
                px: 2,
                mb: 1,
                pb: 1,
                backgroundColor: props.index % 2 === 0 ? theme.palette.background.paper : theme.palette.background.default
                // backgroundColor: theme.palette.background.paper,
            }}>
                <Flexbox direction='column' sx={{ alignItems: "flex-start" }}>
                    {
                        props.reservation.slot && <Typography sx={{ fontSize: 18, color: theme.palette.primary.main, mt: 1 }}>
                            {dayjs(props.reservation.slot.occurredAt).locale('fr').format(EnumDayjsFormat.day_month_name_year)}
                        </Typography>
                    }
                    <Typography sx={{ fontSize: 14, fontStyle: "italic" }}>
                        {`Nº de réservation: ${props.reservation.orderNumber}`}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                        {props.reservation.nbPersons} personnes / {props.reservation.nbCompanions} accompagnants
                    </Typography>

                    {
                        props.reservation.user.legalStatus === EnumUserLegalStatus.particular && <>
                            {
                                (props.reservation.payment &&
                                    props.reservation.payment.paymentStatus === EnumPaymentStatus.PAID) ?
                                    <Typography sx={{ fontSize: 14, color: theme.palette.success.main }}>
                                        <span title={`Order de paiement: ${props.reservation.id}`}>
                                            Confirmé
                                        </span>
                                    </Typography>
                                    :
                                    <Typography sx={{ fontSize: 14, color: theme.palette.error.main }}>
                                        <span title={`Order de paiement: ${props.reservation.id}`}>
                                            Non confirmé !
                                        </span>
                                    </Typography>
                            }
                        </>
                    }
                    {
                        props.reservation.user.legalStatus === EnumUserLegalStatus.enterprise && <>
                            <Typography sx={{ fontSize: 14 }}>
                                {`Agent: ${props.reservation.user.firstname} ${props.reservation.user.lastname}`}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }}>
                                {`Référente: ${displayReferenteInfos()}`}
                            </Typography>
                        </>
                    }
                </Flexbox>
            </Flexbox>
        </>
    )
}


