import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { EnumMazenieneLogoMode, EnumMessageType } from '../enums'
import { useMutation } from '@apollo/client'
import { addMessage } from "../components/messages/Message"
import { useDispatch } from 'react-redux'
import { setJwt } from '../redux/features/global/apolloSlice'
import { useNavigate } from 'react-router'
import { MazenieneLogo } from '../components/MazenieneLogo'
import { setCurrentUser } from '../redux/features/users/currentUserSlice'
import { GQL_MUTATION_SIGNUP } from '../graphql/Mutations'
import { Layout } from './Layout'
import { Flexbox } from '../components/utils/Flexbox'
import { NAVIGATE_ROOT } from '../utils/Navigation'

interface IProps {
}

export const SignupPage: FC<IProps> = (props) => {
  const [firstname, setFirstname] = useState("")
  const [errorOnFirstname, setErrorOnFirstname] = useState(false)
  const [lastname, setLastname] = useState("")
  const [errorOnLastname, setErrorOnLastname] = useState(false)
  const [phone, setPhone] = useState("")
  const [errorOnPhone, setErrorOnPhone] = useState(false)
  const [email, setEmail] = useState("")
  const [errorOnEmail, setErrorOnEmail] = useState(false)
  const [password, setPassword] = useState("")
  const [errorOnPassword, setErrorOnPassword] = useState(false)
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [errorOnPasswordConfirmation, setErrorOnPasswordConfirmation] = useState(false)
  const [comment, setComment] = useState("")



  const [signup, { data: signupData, error: signupError }] = useMutation(GQL_MUTATION_SIGNUP)

  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (signupError) {
      addMessage({
        location: "Signup",
        type: EnumMessageType.Error,
        message: signupError.message,
      })
    } else if (signupData) {
      if (signupData.signup.statusCode === 200) {
        dispatch(setJwt(signupData.signup.authToken))
        dispatch(setCurrentUser(signupData.signup.user))
        addMessage({
          type: EnumMessageType.Success,
          message: "Inscription effectuée.",
        })
        // Refresh page: (redirect sur home au reload..)
        // navigate(0)
        // navigate(NAVIGATE_ADMIN_PAGE)
        navigate(-1)
      } else {
        signupData.signup.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [signupData, signupError])

  const checkErrors = () => {
    let res = true
    if (email === undefined || email === "") {
      setErrorOnEmail(true)
      res = false
    } else {
      setErrorOnEmail(false)
    }
    if (firstname === undefined || firstname === "") {
      setErrorOnFirstname(true)
      res = false
    } else {
      setErrorOnFirstname(false)
    }
    if (lastname === undefined || lastname === "") {
      setErrorOnLastname(true)
      res = false
    } else {
      setErrorOnLastname(false)
    }
    if (phone === undefined || phone === "") {
      setErrorOnPhone(true)
      res = false
    } else {
      setErrorOnPhone(false)
    }
    if (password === undefined || password === "") {
      setErrorOnPassword(true)
      res = false
    } else {
      setErrorOnPassword(false)
    }
    if (password !== passwordConfirmation) {
      setErrorOnPasswordConfirmation(true)
      res = false
    } else {
      setErrorOnPasswordConfirmation(false)
    }
    return res
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (checkErrors()) {
      signup({
        variables: {
          firstname,
          lastname,
          phone,
          email,
          password,
          comment,
        }
      })
    }
  };

  return (
    <div className="SignupPage">
      <Layout>
        <Flexbox direction='column' sx={{ mx: 4, mt: 4 }}>
          <Flexbox direction='column' sx={{ maxWidth: "600px" }}>

            <MazenieneLogo mode={EnumMazenieneLogoMode.standard} sx={{ width: "200px" }} />
            <Typography color="text.primary" sx={{ fontFamily: theme.typography.secondary, fontSize: 24 }}>
              Inscription
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="lastname"
                label="Nom"
                name="lastname"
                autoComplete="lastname"
                autoFocus
                color="primary"
                onChange={(event) => { setLastname(event.target.value) }}
                value={lastname}
                error={errorOnLastname}
                helperText={errorOnLastname ? "Ce champ est obligatoire." : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="firstname"
                label="Prénom"
                name="firstname"
                autoComplete="firstname"
                color="primary"
                onChange={(event) => { setFirstname(event.target.value) }}
                value={firstname}
                error={errorOnFirstname}
                helperText={errorOnFirstname ? "Ce champ est obligatoire." : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Téléphone"
                name="phone"
                autoComplete="phone"
                color="primary"
                onChange={(event) => { setPhone(event.target.value) }}
                value={phone}
                error={errorOnPhone}
                helperText={errorOnPhone ? "Ce champ est obligatoire." : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                color="primary"
                onChange={(event) => { setEmail(event.target.value) }}
                value={email}
                error={errorOnEmail}
                helperText={errorOnEmail ? "Ce champ est obligatoire." : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                onChange={(event) => { setPassword(event.target.value) }}
                value={password}
                error={errorOnPassword}
                helperText={errorOnPassword ? "Ce champ est obligatoire." : ""}
              />
              <Typography sx={{
                color: theme.palette.text.disabled,
              }}>
                8 caractères minimum
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordConfirmation"
                label="Confirmation"
                type="password"
                id="passwordConfirmation"
                onChange={(event) => { setPasswordConfirmation(event.target.value) }}
                value={passwordConfirmation}
                error={errorOnPasswordConfirmation}
                helperText={errorOnPasswordConfirmation ? "Les champs doivent correspondre." : ""}
              />
              <TextField
                margin="normal"
                label="Commentaire (allergènes, restrictions, etc.)"
                fullWidth
                multiline
                rows={4}
                onChange={(event) => { setComment(event.target.value) }}
                value={comment}
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}
              >
                S'inscrire
              </Button>
            </Box>
          </Flexbox>
        </Flexbox>
      </Layout>
    </div >
  )
}
