import { createSlice } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/fr';
import { IReservation, IReservationsFilters, ISlot } from "../../../interfaces";
import { apolloClient } from "../../../App";
import { IRootState, store } from "../../store";
import { GQL_SLOTS } from "../../../graphql/Queries";
import { useSelector } from "react-redux";
import { matchDateAfter, matchDateBefore, matchStrings } from "../../../utils/helpers";



interface IGlobalSliceState {
    datePlanning: string,
    slots: ISlot[],
    reservationsFilters: IReservationsFilters,
}

const initialState: IGlobalSliceState = {
    datePlanning: dayjs().locale('fr').format(),
    slots: [],
    reservationsFilters: {
        refPersonQuery: "",
        agentQuery: "",
        orderNumberQuery: "",
        dateFrom: "",
        dateTo: "",
    }
}

export const globalSlice = createSlice({
    name: "globalSlice",
    initialState,
    reducers: {
        setDatePlanningAction: (state, action) => {
            state.datePlanning = action.payload
        },
        getSlots: (state,) => {
            fetchSlots()
        },
        setSlots: (state, action) => {
            state.slots = action.payload
        },
        setReservationsFilters: (state, action) => {
            state.reservationsFilters = action.payload
        },
    }
})

export const {
    setDatePlanningAction,
    getSlots,
    setSlots,
    setReservationsFilters,
} = globalSlice.actions


const fetchSlots = () => {
    // console.log("fetching slots..")
    apolloClient.query({
        query: GQL_SLOTS,
        fetchPolicy: 'network-only',
        // variables: {
        //     reference: reference,
        // }
    }
    ).then(response => {
        store.dispatch(setSlots(response.data.slots))
    })
}

export const useGetSlotByDate = (date: Dayjs) => {
    let res: ISlot | undefined
    const slots: ISlot[] = useSelector((state: IRootState) => state.globalReducer.slots)
    slots.forEach(slot => {
        // console.log("slot.occurredAt: ", slot.occurredAt)
        const occuredAtDayjs = dayjs(slot.occurredAt)
        // console.log("occuredAtDayjs", occuredAtDayjs)
        if (occuredAtDayjs.isSame(date, 'day')) {
            res = slot
        }
    })
    return res
}

export const useGetFilteredReservations = (reservations: IReservation[] | undefined) => {
    let res: IReservation[] = []
    const reservationsFilters: IReservationsFilters = useSelector((state: IRootState) => state.globalReducer.reservationsFilters)
    reservations?.forEach(reservation => {
        if (
            (
                matchStrings(reservationsFilters.refPersonQuery, reservation.refPersonFirstname) ||
                matchStrings(reservationsFilters.refPersonQuery, reservation.refPersonLastname) ||
                matchStrings(reservationsFilters.refPersonQuery, reservation.refPersonEmail)
            ) &&
            (
                matchStrings(reservationsFilters.agentQuery, reservation.user.firstname) ||
                matchStrings(reservationsFilters.agentQuery, reservation.user.lastname) ||
                matchStrings(reservationsFilters.agentQuery, reservation.user.email)
            ) &&
            (
                matchStrings(reservationsFilters.orderNumberQuery, reservation.orderNumber)
            ) &&
            (
                matchDateBefore(reservationsFilters.dateFrom, reservation.slot.occurredAt)
            ) &&
            (
                matchDateAfter(reservationsFilters.dateTo, reservation.slot.occurredAt)
            )
        ) {
            res.push(reservation)
        }
    })
    return res
}

export default globalSlice.reducer


