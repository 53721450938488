import { FC, useState } from 'react'
import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material'
import { Flexbox } from './utils/Flexbox'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../redux/store'
import { setSlotAvailableQuantity, setSlotCompanionPrice, setSlotParametersMode, setSlotPrice } from '../redux/features/admin/adminSlice'
import { EnumMessageType, EnumSlotParametersMode } from '../enums'
import { addMessage } from './messages/Message'

interface IProps {
}

export const SlotParameters: FC<IProps> = (props) => {
  const nbPlaces: number = useSelector((state: IRootState) => state.adminReducer.slotAvailableQuantity)
  const price: number = useSelector((state: IRootState) => state.adminReducer.slotPrice)
  const companionPrice: number = useSelector((state: IRootState) => state.adminReducer.slotCompanionPrice)
  const slotParametersMode: EnumSlotParametersMode = useSelector((state: IRootState) => state.adminReducer.slotParametersMode)

  const theme = useTheme()
  const dispatch = useDispatch()

  const handleChangeParametersMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === EnumSlotParametersMode.creation) {
      dispatch(setSlotParametersMode(EnumSlotParametersMode.creation))
    } else if (event.target.value === EnumSlotParametersMode.information) {
      dispatch(setSlotParametersMode(EnumSlotParametersMode.information))
    } else if (event.target.value === EnumSlotParametersMode.suppression) {
      dispatch(setSlotParametersMode(EnumSlotParametersMode.suppression))
    } else {
      addMessage({
        location: "SlotParameters",
        type: EnumMessageType.Error,
        message: "Mode de slot parameters inconnu.",
      })
    }
  }

  return (
    <>
      <Flexbox direction='column' sx={{
        // my: 4,
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderColor: theme.palette.primary.main,
        borderRadius: 2,
        py: 2,
        px: 2,
        // width: "100%",
        backgroundColor: "white",
        // minWidth: "800px",
        minWidth: { xs: "none", sm: "600px" },
      }}>
        <Typography sx={{
          color: theme.palette.primary.main,
          fontSize: 18,
        }}>
          Paramètres :
        </Typography>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={slotParametersMode}
            onChange={handleChangeParametersMode}
          >
            <Flexbox sx={{
              justifyContent: "space-around",
            }}>
              <FormControlLabel sx={{ color: theme.palette.info.main }} value={EnumSlotParametersMode.information}
                control={<Radio sx={{
                  color: theme.palette.info.main,
                  '&.Mui-checked': {
                    color: theme.palette.info.main,
                  },
                }} />} label="Détails" />
              <FormControlLabel sx={{ color: theme.palette.success.main }} value={EnumSlotParametersMode.creation}
                control={<Radio sx={{
                  color: theme.palette.success.main,
                  '&.Mui-checked': {
                    color: theme.palette.success.main,
                  },
                }} />} label="Création" />
              <FormControlLabel sx={{ color: theme.palette.error.main }} value={EnumSlotParametersMode.suppression}
                control={<Radio sx={{
                  color: theme.palette.error.main,
                  '&.Mui-checked': {
                    color: theme.palette.error.main,
                  },
                }} />} label="Suppression" />
            </Flexbox>
          </RadioGroup>
        </FormControl>
        {
          slotParametersMode === EnumSlotParametersMode.creation &&
          <Flexbox sx={{ gap: 1, justifyContent: "space-around", width: "100%", }}>
            <TextField
              margin="normal"
              required
              label="Nombre de places"
              type='number'
              color="primary"
              onChange={(event) => { dispatch(setSlotAvailableQuantity(parseInt(event.target.value))) }}
              value={nbPlaces}
            />
            <TextField
              margin="normal"
              required
              type="number"
              label="Prix unitaire"
              color="primary"
              onChange={(event) => { dispatch(setSlotPrice(parseFloat(event.target.value))) }}
              value={price}
            />
            <TextField
              margin="normal"
              required
              type="number"
              label="Prix accompagnant"
              color="primary"
              onChange={(event) => { dispatch(setSlotCompanionPrice(parseFloat(event.target.value))) }}
              value={companionPrice}
            />
          </Flexbox>
        }
      </Flexbox>
    </>
  )
}
