import { createSlice } from "@reduxjs/toolkit";
import { IReservation } from "../../../interfaces";


interface IPaymentSliceState {
    reservation: IReservation | undefined,
    formToken: string,
}

const initialState: IPaymentSliceState = {
    reservation: undefined,
    formToken: "",
}

export const paymentSlice = createSlice({
    name: "paymentSlice",
    initialState,
    reducers: {
        setReservation: (state, action) => {
            state.reservation = action.payload
        },
        setFormToken: (state, action) => {
            state.formToken = action.payload
        },
    }
})

export const {
    setReservation,
    setFormToken,
} = paymentSlice.actions



export default paymentSlice.reducer


