import { FC } from 'react'
import { Divider, Typography, useTheme } from '@mui/material'
import { Flexbox } from './utils/Flexbox'

interface IProps {
  title: string,
}

export const MainTitle: FC<IProps> = (props) => {
  const theme = useTheme()

  return (
    <>
      <Flexbox direction='column' sx={{
        my: 4,
      }}>
        <Typography sx={{
          color: theme.palette.primary.main,
          fontSize: 32,
        }}>
          {props.title}
        </Typography>
        <Divider sx={{
          borderColor: theme.palette.secondary.main,
          width: "100%",
        }} />
      </Flexbox>
    </>
  )
}
