import { FC, useState } from 'react'
import { Box, Button, Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { Flexbox } from './utils/Flexbox'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../redux/store'
import { setSlotAvailableQuantity, setSlotCompanionPrice, setSlotParametersMode, setSlotPrice } from '../redux/features/admin/adminSlice'
import { EnumMessageType, EnumSlotParametersMode, EnumUserLegalStatus } from '../enums'
import { addMessage } from './messages/Message'
import { IReservationsFilters } from '../interfaces'
import { setReservationsFilters } from '../redux/features/global/globalSlice'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { ModalDatePicker } from './dialogs/ModalDatePicker'

import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { useGetCurrentUser } from '../redux/features/users/currentUserSlice'

interface IProps {
}

export const ReservationsFilters: FC<IProps> = (props) => {
  // const reservationsFilters: IReservationsFilters = useSelector((state: IRootState) => state.globalReducer.reservationsFilters)
  const [refPersonQuery, setRefPersonQuery] = useState("")
  const [agentQuery, setAgentQuery] = useState("")
  const [orderNumberQuery, setOrderNumberQuery] = useState("")

  const [dateFrom, setDateFrom] = useState("")
  const [showModalDatePickerFrom, setShowModalDatePickerFrom] = useState(false)

  const [dateTo, setDateTo] = useState("")
  const [showModalDatePickerTo, setShowModalDatePickerTo] = useState(false)

  const currentUser = useGetCurrentUser()

  const theme = useTheme()
  const dispatch = useDispatch()

  const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const newReservationsFilters: IReservationsFilters = {
      refPersonQuery,
      agentQuery,
      orderNumberQuery,
      dateFrom,
      dateTo,
    }
    dispatch(setReservationsFilters(newReservationsFilters))
  }

  const handleDateFrom = () => {
    setShowModalDatePickerFrom(true)
  }

  const closeModalDatePickerFrom = () => {
    setShowModalDatePickerFrom(false)
  }

  const confirmModalDatePickerFrom = (pDate: Dayjs) => {
    setDateFrom(pDate.format("DD/MM/YYYY"))
  }

  const resetModalDatePickerFrom = () => {
    setDateFrom("")
  }

  const handleDateTo = () => {
    setShowModalDatePickerTo(true)
  }

  const closeModalDatePickerTo = () => {
    setShowModalDatePickerTo(false)
  }

  const confirmModalDatePickerTo = (pDate: Dayjs) => {
    setDateTo(pDate.format("DD/MM/YYYY"))
  }

  const resetModalDatePickerTo = () => {
    setDateTo("")
  }


  return (
    <>
      <Box component="form" onSubmit={handleConfirm}>

        <Flexbox direction='column' sx={{
          borderRadius: 2,
          // py: 2,
          px: 2,
          backgroundColor: "white",
          // minWidth: { xs: "none", sm: "600px" },
          mb: 2,
          pb: 2,
        }}>
          {/* <Typography sx={{
          color: theme.palette.primary.main,
          fontSize: 18,
        }}>
          Filtres :
        </Typography> */}

          <Grid container spacing={2} sx={{ mt: 0, mb: 2 }} >
            {
              currentUser?.legalStatus === EnumUserLegalStatus.particular && <>
                {/* <Grid xs={4} sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}>
                  <Typography sx={{
                    fontSize: 18,

                  }}>
                    Nº de réservation :
                  </Typography>
                </Grid> */}
                {/* <Grid xs={8} sm={6} sx={{ */}
                <Grid xs={12} sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}>
                  <TextField variant='standard'
                    fullWidth
                    margin="dense"
                    label="Nº de réservation"
                    color="secondary"
                    onChange={(event) => { setOrderNumberQuery(event.target.value) }}
                    value={orderNumberQuery}
                    autoComplete='off'
                    sx={{
                      mt: 0,
                    }}
                  />
                </Grid>
              </>
            }
            {
              currentUser?.legalStatus === EnumUserLegalStatus.enterprise && <>
                <Grid xs={12} sm={4}>
                  <TextField variant='standard'
                    fullWidth
                    margin="dense"
                    label="Nº de réservation"
                    color="secondary"
                    onChange={(event) => { setOrderNumberQuery(event.target.value) }}
                    value={orderNumberQuery}
                    autoComplete='off'
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <TextField variant='standard'
                    fullWidth
                    margin="dense"
                    label="Référente"
                    color="secondary"
                    onChange={(event) => { setRefPersonQuery(event.target.value) }}
                    value={refPersonQuery}
                    autoComplete='off'
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <TextField variant='standard'
                    fullWidth
                    margin="dense"
                    label="Agent"
                    color="secondary"
                    onChange={(event) => { setAgentQuery(event.target.value) }}
                    value={agentQuery}
                    autoComplete='off'
                  />
                </Grid>
              </>
            }
            <Grid container xs={12} sx={{}}>
              <Grid xs={4} sx={{}}>
                {/* <Flexbox sx={{ justifyContent: "space-around", alignItems: "center", mt: 0, }}> */}
                <Typography sx={{
                  fontSize: 18,
                }}>
                  Période :
                </Typography>
              </Grid>
              <Grid xs={4} sx={{}}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}>
                  <Tooltip title="Depuis">
                    <IconButton onClick={handleDateFrom} color="primary">
                      <EventOutlinedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                  <Box sx={{ borderBottom: dateFrom === "" ? `1px solid ${theme.palette.grey[500]}` : "none", width: "100%" }}>
                    {
                      dateFrom !== ""
                        ?
                        <Typography variant="body2" color="secondary"  >
                          {dateFrom}
                        </Typography>
                        :
                        <Typography variant="body2"  >
                          &nbsp;
                        </Typography>
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid xs={4} sx={{}}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}>
                  <Tooltip title="Jusqu'au">
                    <IconButton onClick={handleDateTo} color="primary">
                      <EventOutlinedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                  <Box sx={{ borderBottom: dateTo === "" ? `1px solid ${theme.palette.grey[500]}` : "none", width: "100%" }}>
                    {
                      dateTo !== ""
                        ?
                        <Typography variant="body2" color="secondary"  >
                          {dateTo}
                        </Typography>
                        :
                        <Typography variant="body2"  >
                          &nbsp;
                        </Typography>
                    }
                  </Box>
                </Box>
              </Grid>

              {/* </Flexbox> */}
            </Grid>
          </Grid>
          <Flexbox sx={{ justifyContent: "flex-end", width: "100%" }}>
            <Button type='submit' variant="outlined" color="secondary">Filtrer</Button>
          </Flexbox>
        </Flexbox>
      </Box>
      <ModalDatePicker
        title="Date de début"
        disableFuture={false}
        open={showModalDatePickerFrom}
        handleClose={closeModalDatePickerFrom}
        handleConfirm={confirmModalDatePickerFrom}
        handleReset={resetModalDatePickerFrom}
      />
      <ModalDatePicker
        title="Date de fin"
        disableFuture={false}
        open={showModalDatePickerTo}
        handleClose={closeModalDatePickerTo}
        handleConfirm={confirmModalDatePickerTo}
        handleReset={resetModalDatePickerTo}
      />
    </>
  )
}
