import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { Flexbox } from '../utils/Flexbox';
import { ISlot } from '../../interfaces';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { EnumDayjsFormat } from '../../enums';
import { ReservationItem } from '../reservations/ReservationItem';


interface IProps {
    open: boolean,
    handleClose: () => void,
    slotOfDay: ISlot,
}

export const SlotInformationsDialog: FC<IProps> = (props) => {
    const theme = useTheme()


    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
            >
                <Flexbox direction='column' >
                    <DialogTitle color="primary">
                        Atelier du {dayjs(props.slotOfDay.occurredAt).format(EnumDayjsFormat.date)}
                    </DialogTitle>
                    <DialogContent sx={{
                        width: "100%",
                        px: 0,
                    }} >
                        {
                            props.slotOfDay.accessibleReservations.length === 0 &&
                            <Typography sx={{ textAlign: "center" }}>
                                Aucune réservation.
                            </Typography>
                        }
                        {
                            props.slotOfDay.accessibleReservations.map((reservation, index) =>
                                <ReservationItem key={`reservation-key-${index}`} index={index} reservation={reservation} />
                            )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClose} variant="outlined" color="cancel">Fermer</Button>
                    </DialogActions>
                </Flexbox>
            </Dialog >
        </>
    )
}
