import { FC, useEffect, useState } from 'react';
import { Box, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { IUser, IUserPrototype } from '../../interfaces';
import { Flexbox } from '../utils/Flexbox';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { UserItem } from './UserItem';
import { getUsers, setUsersQuery } from '../../redux/features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
import { Spinner } from '../Spiner';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { InviteUserDialog } from './InviteUserDialog';
import { GQL_MUTATION_INVITE_USER } from '../../graphql/Mutations';

interface IProps {
}

export const ListUsers: FC<IProps> = (props) => {

    const users: IUser[] = useSelector((state: IRootState) => state.usersReducer.users)
    const usersLoading: boolean = useSelector((state: IRootState) => state.usersReducer.usersLoading)
    const [searchUsersQuery, setSearchUsersQuery] = useState<string>(useSelector((state: IRootState) => state.usersReducer.searchUsersQuery))


    const [showInviteUserDialog, setShowInviteUserDialog] = useState(false)
    const [inviteUser, { data: inviteUserData, loading: inviteUserLoading, error: inviteUserError }] = useMutation(GQL_MUTATION_INVITE_USER)

    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const searchUsers = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // event.stopPropagation()
        dispatch(setUsersQuery(searchUsersQuery))

    }

    const closeInviteUserDialog = () => {
        setShowInviteUserDialog(false)
    }

    const confirmInviteUserDialog = (userPrototype: IUserPrototype) => {
        inviteUser({
            variables: userPrototype
        })
        setShowInviteUserDialog(false)
    }

    useEffect(() => {
        if (inviteUserError) {
            addMessage({
                type: EnumMessageType.Error,
                message: inviteUserError.message,
            })
        } else if (inviteUserData) {
            if (inviteUserData.inviteUser.statusCode === 200) {
                dispatch(getUsers())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Utilisateur créé.",
                })
            } else {
                inviteUserData.inviteUser.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [inviteUserData, inviteUserError])


    return (
        <>
            <Flexbox direction='column' sx={{
                width: "100%",
                maxWidth: "1024px",
                backgroundColor: "white",
                px: 2,
                pb: 1,
                borderRadius: 4,
            }}>
                <Flexbox sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    pb: 1,
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.palette.primary.main,
                }}>
                    <Box component="form" onSubmit={searchUsers} noValidate>
                        <TextField label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <SearchIcon sx={{ mr: 1, my: 0.5 }} />
                                <Typography>Rechercher..</Typography>
                            </Box>
                        } color="secondary"
                            size="small"
                            variant="standard"
                            onChange={(event) => setSearchUsersQuery(event.target.value)}
                            value={searchUsersQuery}
                            sx={{
                                "div::before": { borderBottomStyle: "none !important" },
                                "div::after": { borderBottomStyle: "none !important" },
                            }}
                        />
                    </Box>
                    <IconButton color="secondary" onClick={() => setShowInviteUserDialog(true)}><AddIcon className="addCircleIcon" /></IconButton>
                </Flexbox>
                <Flexbox direction='column' sx={{ width: "100%" }}>
                    {
                        usersLoading ? <Spinner /> : <>
                            {
                                users !== undefined && users.map((user, index) => {
                                    return (
                                        <UserItem index={index} key={user.id} user={user} sx={{ py: 1 }} />
                                    )
                                })
                            }
                        </>
                    }
                </Flexbox>
                <Flexbox sx={{ width: "100%", mt: 1, borderTopStyle: "solid", borderTopWidth: 1, borderTopColor: theme.palette.text.disabled, justifyContent: "flex-end" }}>
                    {
                        users !== undefined && <Typography variant="body2" sx={{ color: theme.palette.primary.main, px: 1 }}>
                            {`${users.length} affichés`}
                        </Typography>
                    }
                </Flexbox>
            </Flexbox>
            <InviteUserDialog
                open={showInviteUserDialog}
                handleClose={closeInviteUserDialog}
                handleConfirm={confirmInviteUserDialog}
            />
        </>
    )
}


