import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { IUserPrototype } from '../../interfaces';
import { Flexbox } from '../utils/Flexbox';
import { EnumMessageType, EnumUserLegalStatus } from '../../enums';
import { addMessage } from '../messages/Message';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (userPrototype: IUserPrototype) => void;
}

export const InviteUserDialog: FC<IProps> = (props) => {

    const theme = useTheme()

    const initialState: IUserPrototype = {
        legalStatus: EnumUserLegalStatus.enterprise,
        enterpriseName: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        comment: "",
    }
    var stateToSend: IUserPrototype = initialState

    const [legalStatus, setLegalStatus] = useState<EnumUserLegalStatus>(initialState.legalStatus)
    const [enterpriseName, setEnterpriseName] = useState<string | undefined>(initialState.enterpriseName)
    const [email, setEmail] = useState<string>(initialState.email)
    const [firstname, setFirstname] = useState<string>(initialState.firstname)
    const [lastname, setLastname] = useState<string>(initialState.lastname)
    const [phone, setPhone] = useState<string>(initialState.phone)
    const [comment, setComment] = useState<string>(initialState.comment)

    const prepareStateToSend = () => {
        stateToSend = {
            legalStatus,
            enterpriseName,
            email,
            firstname,
            lastname,
            phone,
            comment,
        }
    }

    const resetState = () => {
        setLegalStatus(initialState.legalStatus)
        setEnterpriseName(initialState.enterpriseName)
        setEmail(initialState.email)
        setFirstname(initialState.firstname)
        setLastname(initialState.lastname)
        setPhone(initialState.phone)
        setComment(initialState.comment)
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // On unset les valeur en cas de rappel de la dialog:
        prepareStateToSend()
        resetState()
        props.handleConfirm(stateToSend)
    }

    const handleChangeLegalStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === EnumUserLegalStatus.enterprise) {
            setLegalStatus(EnumUserLegalStatus.enterprise)
        } else if (event.target.value === EnumUserLegalStatus.particular) {
            setLegalStatus(EnumUserLegalStatus.particular)
        } else {
            addMessage({
                location: "InviteUserDialog",
                type: EnumMessageType.Error,
                message: "Statut juridique de l'utilisateur inconnu.",
            })
        }
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
            >
                <Box component="form" onSubmit={handleConfirm}>
                    <DialogTitle color="primary">
                        Nouvel utilisateur
                    </DialogTitle>
                    <DialogContent>
                        <Flexbox direction='column'
                            sx={{
                                marginTop: 1,
                            }}
                        >

                            <FormControl sx={{ width: "100%" }}>
                                <RadioGroup
                                    value={legalStatus}
                                    onChange={handleChangeLegalStatus}
                                >
                                    <Flexbox sx={{
                                        justifyContent: "space-around",
                                    }}>
                                        <FormControlLabel sx={{ color: theme.palette.primary.main }} value={EnumUserLegalStatus.enterprise}
                                            control={<Radio sx={{
                                                color: theme.palette.primary.main,
                                                '&.Mui-checked': {
                                                    color: theme.palette.primary.main,
                                                },
                                            }} />} label="Entreprise" />
                                        <FormControlLabel sx={{ color: theme.palette.primary.main }} value={EnumUserLegalStatus.particular}
                                            control={<Radio sx={{
                                                color: theme.palette.primary.main,
                                                '&.Mui-checked': {
                                                    color: theme.palette.primary.main,
                                                },
                                            }} />} label="Particulier" />
                                    </Flexbox>
                                </RadioGroup>
                            </FormControl>

                            {
                                legalStatus === EnumUserLegalStatus.enterprise && <>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Raison sociale"
                                        autoFocus
                                        color="primary"
                                        onChange={(event) => { setEnterpriseName(event.target.value) }}
                                        value={enterpriseName}
                                        autoComplete='off'
                                    />
                                </>
                            }
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Nom"
                                color="primary"
                                onChange={(event) => { setLastname(event.target.value) }}
                                value={lastname}
                                autoComplete='off'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Prénom"
                                color="primary"
                                onChange={(event) => { setFirstname(event.target.value) }}
                                value={firstname}
                                autoComplete='off'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Téléphone"
                                color="primary"
                                onChange={(event) => { setPhone(event.target.value) }}
                                value={phone}
                                autoComplete='off'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email"
                                color="primary"
                                onChange={(event) => { setEmail(event.target.value) }}
                                value={email}
                                autoComplete='off'
                                type="email"
                            />
                            <TextField
                                margin="normal"
                                label="Commentaire"
                                fullWidth
                                multiline
                                rows={4}
                                onChange={(event) => { setComment(event.target.value) }}
                                value={comment}
                            />

                        </Flexbox>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type='submit' variant="outlined" color="primary">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}
