import { FC } from 'react'
import { Flexbox } from '../../components/utils/Flexbox'
import { AgencyLayout } from './AgencyLayout'
import { MainTitle } from '../../components/MainTitle'
import { ListAgents } from '../../components/agents/ListAgents'

interface IProps {
}

export const AgencyAgentsPage: FC<IProps> = (props) => {

  return (
    <div className="AgencyAgentsPage">
      <AgencyLayout>
        <Flexbox direction='column'>
          <MainTitle title="Agents" />
          <ListAgents />
        </Flexbox>
      </AgencyLayout>
    </div >
  )
}
