import { FC, useEffect, useState } from 'react';
import { Box, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { IAgentPrototype, IUser } from '../../interfaces';
import { Flexbox } from '../utils/Flexbox';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { getUsers, setUsersQuery } from '../../redux/features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
import { Spinner } from '../Spiner';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { GQL_MUTATION_INVITE_AGENT } from '../../graphql/Mutations';
import { AgentItem } from './AgentItem';
import { InviteAgentDialog } from './InviteAgentDialog';

interface IProps {
}

export const ListAgents: FC<IProps> = (props) => {

    const agents: IUser[] = useSelector((state: IRootState) => state.usersReducer.users)
    const agentsLoading: boolean = useSelector((state: IRootState) => state.usersReducer.usersLoading)
    const [searchAgentsQuery, setSearchAgentsQuery] = useState<string>(useSelector((state: IRootState) => state.usersReducer.searchUsersQuery))


    const [showInviteAgentDialog, setShowInviteAgentDialog] = useState(false)
    const [inviteAgent, { data: inviteAgentData, loading: inviteAgentLoading, error: inviteAgentError }] = useMutation(GQL_MUTATION_INVITE_AGENT)

    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const searchAgents = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // event.stopPropagation()
        dispatch(setUsersQuery(searchAgentsQuery))

    }

    const closeInviteAgentDialog = () => {
        setShowInviteAgentDialog(false)
    }

    const confirmInviteAgentDialog = (agentPrototype: IAgentPrototype) => {
        inviteAgent({
            variables: agentPrototype
        })
        setShowInviteAgentDialog(false)
    }

    useEffect(() => {
        if (inviteAgentError) {
            addMessage({
                type: EnumMessageType.Error,
                message: inviteAgentError.message,
            })
        } else if (inviteAgentData) {
            if (inviteAgentData.inviteAgent.statusCode === 200) {
                dispatch(getUsers())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Agent créé.",
                })
            } else {
                inviteAgentData.inviteAgent.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [inviteAgentData, inviteAgentError])


    return (
        <>
            <Flexbox direction='column' sx={{
                width: "100%",
                maxWidth: "1024px",
                backgroundColor: "white",
                px: 2,
                pb: 1,
                borderRadius: 4,
            }}>
                <Flexbox sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    pb: 1,
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.palette.primary.main,
                }}>
                    <Box component="form" onSubmit={searchAgents} noValidate>
                        <TextField label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <SearchIcon sx={{ mr: 1, my: 0.5 }} />
                                <Typography>Rechercher..</Typography>
                            </Box>
                        } color="secondary"
                            size="small"
                            variant="standard"
                            onChange={(event) => setSearchAgentsQuery(event.target.value)}
                            value={searchAgentsQuery}
                            sx={{
                                "div::before": { borderBottomStyle: "none !important" },
                                "div::after": { borderBottomStyle: "none !important" },
                            }}
                        />
                    </Box>
                    <IconButton color="secondary" onClick={() => setShowInviteAgentDialog(true)}><AddIcon className="addCircleIcon" /></IconButton>
                </Flexbox>
                <Flexbox direction='column' sx={{ width: "100%" }}>
                    {
                        agentsLoading ? <Spinner /> : <>
                            {
                                agents !== undefined && agents.map((agent, index) => {
                                    return (
                                        <AgentItem index={index} key={agent.id} agent={agent} sx={{ py: 1 }} />
                                    )
                                })
                            }
                        </>
                    }
                </Flexbox>
                <Flexbox sx={{ width: "100%", mt: 1, borderTopStyle: "solid", borderTopWidth: 1, borderTopColor: theme.palette.text.disabled, justifyContent: "flex-end" }}>
                    {
                        agents !== undefined && <Typography variant="body2" sx={{ color: theme.palette.primary.main, px: 1 }}>
                            {`${agents.length} affichés`}
                        </Typography>
                    }
                </Flexbox>
            </Flexbox>
            <InviteAgentDialog
                open={showInviteAgentDialog}
                handleClose={closeInviteAgentDialog}
                handleConfirm={confirmInviteAgentDialog}
            />
        </>
    )
}


