import { FC, useEffect, useState } from 'react'


import { useDispatch } from "react-redux"
import { remove, add } from '../../redux/features/global/messagesSlice';
import { IMessage, INewMessage } from '../../interfaces';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { wait } from '@testing-library/user-event/dist/utils';
import { store } from '../../redux/store';
import { IAddMessageParams } from '../../interfaces';
import { EnumMessageType } from '../../enums';



interface IProps {
  message: IMessage;
}


export const Message: FC<IProps> = (props) => {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch()

  const getSeverity = () => {
    if (props.message.type === EnumMessageType.Success) {
      return "success"
    }
    if (props.message.type === EnumMessageType.Error) {
      return "error"
    }
    if (props.message.type === EnumMessageType.Warning) {
      return "warning"
    }
    if (props.message.type === EnumMessageType.Info) {
      return "info"
    }
  }

  const removeMessage = () => {
    if (open) {
      setOpen(false)
      wait(500).then(res =>
        dispatch(remove(props.message.id))
      )
    }
  }

  useEffect(() => {
    wait(5000).then(res => removeMessage())
    // wait(10000).then(res => removeMessage())
    // wait(60000).then(res => removeMessage())
  }, [])



  return (
    <Collapse in={open}>
      <Box maxWidth="444px" sx={{ mb: 1, mx: 1 }}>
        <Alert
          severity={getSeverity()}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={removeMessage}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.message.message}
        </Alert>
      </Box>
    </Collapse>
  )
}




export const addMessage = (addMessageParams: IAddMessageParams) => {
  const compiled_message = addMessageParams.location ?
    `${addMessageParams.location}: ${addMessageParams.message}` :
    addMessageParams.message
  // console.log(compiled_message)
  const message_object: INewMessage = {
    type: addMessageParams.type,
    message: compiled_message,
  }
  // const dispatch = useDispatch()
  store.dispatch(add(message_object))
  return true
}