import * as React from 'react';
import { FC, ReactElement } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { NAVIGATE_ROOT, NAVIGATE_WORKSHOP_PAGE, isActivePath } from '../utils/Navigation';
import { Link, Paper, useTheme } from '@mui/material';
import { MazenieneLogo } from './MazenieneLogo';
import { EnumBuildMode, EnumDevice, EnumMazenieneLogoMode, EnumUserLegalStatus } from '../enums';
import { AvatarMenu } from './AvatarMenu';
import { useGetCurrentUser } from '../redux/features/users/currentUserSlice';
import { MenuList } from './menu/MenuList';

interface IPage {
    title: string
    path: string
}


interface IProps {
    buildMode?: EnumBuildMode,
}


export const ResponsiveAppBar: FC<IProps> = (props) => {
    // const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()




    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleButtonNavMenu = (page: IPage) => {
        setAnchorElNav(null);
        navigate(page.path)
    };


    return (
        <AppBar className='ResponsiveAppBar' position='fixed' sx={{
            // backgroundColor: theme.palette.custom1.main
            // backgroundColor: theme.palette.gris1.main
            backgroundColor: theme.palette.gris3.main
        }}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    {/* mobile */}
                    <Box sx={{ width: '100%', display: { xs: 'flex', md: 'none', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Typography
                            component="a"
                            href={NAVIGATE_ROOT}
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <MazenieneLogo mode={EnumMazenieneLogoMode.accueilMobile} />
                        </Typography>
                        {
                            !props.buildMode && <>
                                <Box>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="tertiary"
                                        className={`menu-icon ${Boolean(anchorElNav) ? "active" : ""}`}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                            // color: theme.palette.primary.main,
                                        }}
                                        slotProps={{
                                            paper: {
                                                sx: {
                                                    ">ul": {
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    },

                                                }
                                            }
                                        }}

                                    >
                                        <MenuList device={EnumDevice.mobile} handleCloseNavMenu={handleCloseNavMenu} handleButtonNavMenu={handleButtonNavMenu} />

                                        <MenuItem
                                            sx={{
                                                backgroundColor: theme.palette.gris3.main,
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <AvatarMenu />
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </>
                        }

                    </Box>

                    {/* desktop */}
                    <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href={NAVIGATE_ROOT}
                            sx={{
                                mr: 2,
                                display: { alignItems: 'center' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'black',
                                textDecoration: 'none',
                            }}
                            className="menu-link"
                        >
                            <MazenieneLogo mode={EnumMazenieneLogoMode.accueil} />
                        </Typography>
                        {
                            !props.buildMode && <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <MenuList device={EnumDevice.desktop} handleCloseNavMenu={handleCloseNavMenu} handleButtonNavMenu={handleButtonNavMenu} />

                                    <AvatarMenu />
                                </Box>
                            </>
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
};
// export default ResponsiveAppBar;
