import { FC } from 'react'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from "./Layout"
import { Footer } from '../sections/Footer'
import { ReunionWorkshopSection } from '../sections/workshops/ReunionWorkshopSection'
import { MainTitle } from '../components/MainTitle'
import { ListReservations } from '../components/reservations/ListReservations'
import { ReservationsFilters } from '../components/ReservationsFilters'

interface IProps {
}

export const MyReservationsPage: FC<IProps> = (props) => {


  return (
    <div className="MyReservationsPage">
      <Layout>
        <Flexbox direction='column'>
          <MainTitle title="Mes réservations" />
          <ReservationsFilters />
          <ListReservations />
        </Flexbox>
      </Layout>
    </div >
  )
}
