import { FC } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Flexbox } from '../components/utils/Flexbox';

interface IProps { }

export const MenuSection: FC<IProps> = (props) => {

    const theme = useTheme()

    return (
        <>
            {/* Mobile */}
            <Box sx={{
                display: { xs: "block", sm: "none" },
                mx: 4,
                my: 2,
                py: 4,
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.gris3.main,
                boxShadow: `3px 2px 2px ${theme.palette.gris3.main}`,
            }}>
                <Flexbox
                    direction='column'
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        mx: 2,
                    }}
                >
                    <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/images/accueil/draft_02.png`}
                        sx={{
                            width: "150px",
                            mb: 2,
                        }}
                    />
                    <Flexbox direction='column'
                        sx={{
                            alignItems: "center",
                            px: 2,
                            width: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                mb: 2,
                                textAlign: "center",
                            }}
                        >
                            Découvrez nos menus qui pimenteront votre journée !
                        </Typography>
                        <Typography>
                            Envie de manger un bon « ti » plat créole,
                            <br />
                            Envie de découvrir les bons produits de notre incroyable terroir réunionnais,
                            <br />
                            Envie de repartir au travail l’estomac léger
                            <br />
                            Etc.
                        </Typography>
                        <Flexbox sx={{ mt: 8, width: "100%", justifyContent: "flex-end" }}>
                            {/* TODO : action button */}
                            <Button variant='outlined' sx={{ color: theme.palette.primary.main }}>
                                Réservez votre table
                            </Button>
                        </Flexbox>

                    </Flexbox>


                </Flexbox>
            </Box>

            {/* Desktop */}
            <Box sx={{
                display: { xs: "none", sm: "block" },
                width: "100%",
                py: 4,
            }}>
                <Flexbox
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        mx: 12,
                    }}
                >

                    <Flexbox direction='column'
                        sx={{
                            alignItems: "flex-start",
                            px: 2,
                            width: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 28,
                                mb: 2,
                            }}
                        >
                            Découvrez nos menus qui pimenteront votre journée !
                        </Typography>
                        <Typography>
                            Envie de manger un bon « ti » plat créole,
                            <br />
                            Envie de découvrir les bons produits de notre incroyable terroir réunionnais,
                            <br />
                            Envie de repartir au travail l’estomac léger
                            <br />
                            Etc.
                        </Typography>
                        <Flexbox sx={{ mt: 8, width: "100%", justifyContent: "flex-end" }}>
                            {/* TODO : action button */}
                            <Button variant='outlined' sx={{ color: theme.palette.primary.main }}>
                                Réservez votre table
                            </Button>
                        </Flexbox>

                    </Flexbox>
                    <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/images/accueil/draft_02.png`}
                        sx={{
                            width: "300px",
                            mx: 2,
                        }}
                    />

                </Flexbox>
            </Box>
        </>
    )
}


