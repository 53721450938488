
import { FC } from 'react'
import { useSelector } from "react-redux"
import { Message } from "./Message"
import { IMessage } from "../../interfaces"
import { Box } from '@mui/material'



interface IProps {
}

export const Messages: FC<IProps> = (props) => {
  const messages: IMessage[] = useSelector((state: any) => state.messagesReducer.messages)
  return (
    <Box sx={{
      marginTop: "80px",
      position: 'fixed',
      top: 0,
      // right: 0,
      zIndex: 1301,
      // zIndex: 1300,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // bgcolor: "red",
    }}>
      {
        messages.map(message => <Message key={message.id} message={message} />)
      }
    </Box>
  )
}


