import { FC, useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Flexbox } from '../../components/utils/Flexbox';
import { WorkshopPlanning } from '../../components/WorkshopPlanning';
import { EnumDevice, EnumWorkshopPlanningMode } from '../../enums';

interface IProps { }

export const ReunionWorkshopSection: FC<IProps> = (props) => {

    const [showPlanning, setShowPlanning] = useState<boolean>(false)

    const theme = useTheme()

    const handleShowPlanning = () => {
        setShowPlanning(true)
    }

    return (
        <Box sx={{ width: "100%", py: 4 }}>
            <Flexbox
                sx={{
                    justifyContent: { xs: "center", sm: "space-between" },
                    alignItems: { xs: "center", sm: "flex-start" },
                    mx: { xs: 0, sm: 12 },
                    flexDirection: { xs: "column", sm: "row" },
                    // bgcolor: "red",
                }}
            >
                <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/images/workshops/draft_06.png`}
                    sx={{
                        width: { xs: "150px", sm: "300px" },
                        mx: 2,
                    }}
                />
                <Flexbox direction='column'
                    sx={{
                        alignItems: "flex-start",
                        // width: "100%",
                        // bgcolor: "red",
                        px: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: 18, sm: 28 },
                            mb: 2,
                        }}
                    >
                        Notre atelier de cuisine réunionnaise
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.main,
                        mb: 2,
                    }}>
                        55.00 € / adulte et 25.00 € / accompagnant
                    </Typography>
                    <Typography sx={{
                        mb: 2,
                    }}>
                        <Typography component="span" sx={{ fontWeight: "600" }}>Durée</Typography> : 4h comprenant l’atelier et le moment de partage du repas.
                    </Typography>
                    <Typography
                        sx={{
                            fontStyle: "italic",
                        }}>
                        Envie de vous immerger dans la gastronomie réunionnaise et découvrir ainsi toutes ces influences venues d’Inde, de Chine ou encore d’Afrique, réservez dès à présent votre atelier de cuisine !
                    </Typography>
                </Flexbox>
            </Flexbox>
            <Box sx={{
                mx: { xs: 2, sm: 14 },
                mt: 2,
                // bgcolor: "red",
            }}>
                Encore du descriptif mais je ne suis pas inspirée pour l’instant
            </Box>

            {
                showPlanning ?
                    <Box sx={{
                        mx: { xs: 0, sm: 14 },
                        mt: 2
                    }}>
                        {/* Mobile */}
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            <WorkshopPlanning mode={EnumWorkshopPlanningMode.standard} device={EnumDevice.mobile} />
                        </Box>
                        {/* Desktop */}
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            <WorkshopPlanning mode={EnumWorkshopPlanningMode.standard} device={EnumDevice.desktop} />
                        </Box>
                    </Box>
                    :
                    <Flexbox sx={{
                        mt: 4
                    }}>
                        <Button variant='outlined' onClick={() => handleShowPlanning()} sx={{ color: theme.palette.primary.main }}>
                            réserver mon atelier cuisine
                        </Button>

                    </Flexbox>
            }
        </Box>
    )
}


