import { FC, useEffect, useState } from 'react'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from "./Layout"
import { MainTitle } from '../components/MainTitle'
import { IReservation, IUser } from '../interfaces'
import { useSelector } from 'react-redux'
import { IRootState } from '../redux/store'
import KRGlue from '@lyracom/embedded-form-glue'
import { Typography, useTheme } from '@mui/material'
import { Spinner } from '../components/Spiner'

interface IProps {
}

export const PaymentSuccessPage: FC<IProps> = (props) => {

  const currentUser: (undefined | IUser) = useSelector((state: IRootState) => state.currentUserReducer.user)
  const theme = useTheme()

  return (
    <div className="paymentSuccessPage">
      <Layout>
        <Flexbox direction='column'>
          <MainTitle title="Paiement effectué" />
          {
            !currentUser && <Spinner />
          }
          {
            currentUser && <Flexbox direction='column'
              sx={{
                maxWidth: "900px",
                backgroundColor: "white",
                px: 8,
                py: 4,
                borderRadius: "12px"
              }}
            >
              <Typography sx={{ fontSize: 24, color: theme.palette.primary.main }}>
                Merci de votre confiance et à très vite !
              </Typography>
              <Typography sx={{ fontSize: 16, color: theme.palette.primary.main }}>
                Un email de confirmation vient de vous être envoyé à l’adresse : {currentUser.email}
              </Typography>
              <Typography sx={{ fontSize: 16, color: theme.palette.primary.main, mt: 2 }}>
                Toute l’équipe du Mazéniène se tient à votre disposition pour de plus amples informations à <Typography
                  component="a"
                  href="mailto:contact@lemazeniene.com"
                  sx={{
                    color: theme.palette.info.main,
                    ":hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: theme.palette.secondary.main,
                    },
                  }}>
                  contact@lemazeniene.com
                </Typography> ou par téléphone au 02.62.12.34.56
              </Typography>
            </Flexbox>
          }
        </Flexbox>
      </Layout>
    </div >
  )
}
