import { FC } from 'react'
import { Flexbox } from '../../components/utils/Flexbox'
import { Layout } from "../Layout"
import { DescriptionSection } from '../../sections/DescriptionSection'
import { MenuSection } from '../../sections/MenuSection'
import { WorkshopSection } from '../../sections/WorkshopSection'
import { EngagementsSection } from '../../sections/EngagementsSection'
import { PrivatizeSection } from '../../sections/PrivatizeSection'
import { Footer } from '../../sections/Footer'
import { MazenieneLogo } from '../../components/MazenieneLogo'
import { EnumMazenieneLogoMode } from '../../enums'
import { Button, Typography, useTheme } from '@mui/material'
import { AgencyLayout } from './AgencyLayout'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_ADMIN_SLOTS_PAGE, NAVIGATE_ADMIN_USERS_PAGE, NAVIGATE_AGENCY_AGENTS_PAGE } from '../../utils/Navigation'
import BusinessIcon from '@mui/icons-material/Business';
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice'

interface IProps {
}

export const AgencyPage: FC<IProps> = (props) => {
  const currentUser = useGetCurrentUser()

  const navigate = useNavigate()
  const theme = useTheme()


  return (
    <div className="AgencyPage">
      <AgencyLayout>
        <Flexbox direction='column'>
          <BusinessIcon sx={{
            color: theme.palette.primary.main,
            fontSize: 48,
            mt: 4,
          }} />
          <Typography sx={{
            fontSize: 48,
          }}>
            {currentUser?.enterpriseName}
          </Typography>
          <Flexbox direction='column' sx={{
            justifyContent: "space-around",
            mt: 2,
          }}
          >
            <Button variant='text' color='primary' onClick={() => navigate(NAVIGATE_AGENCY_AGENTS_PAGE)}>
              Agents
            </Button>
            {/* <Button variant='text' color='primary' onClick={() => navigate(NAVIGATE_ADMIN_USERS_PAGE)}>
              Utilisateurs
            </Button>
            <Button variant='text' color='primary' onClick={() => navigate(NAVIGATE_ADMIN_SLOTS_PAGE)}>
              Créneaux atelier cuisine
            </Button> */}
          </Flexbox>
        </Flexbox>
      </AgencyLayout>
    </div >
  )
}
