import { createSlice } from "@reduxjs/toolkit";
import { EnumSlotParametersMode } from "../../../enums";



interface IAdminSliceState {
    slotAvailableQuantity: number,
    slotPrice: number,
    slotCompanionPrice: number,
    slotParametersMode: EnumSlotParametersMode,
}

const initialState: IAdminSliceState = {
    slotAvailableQuantity: 6,
    slotPrice: 55.00,
    slotCompanionPrice: 25.00,
    slotParametersMode: EnumSlotParametersMode.information,
}

export const adminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        setSlotAvailableQuantity: (state, action) => {
            state.slotAvailableQuantity = action.payload
        },
        setSlotPrice: (state, action) => {
            state.slotPrice = action.payload
        },
        setSlotCompanionPrice: (state, action) => {
            state.slotCompanionPrice = action.payload
        },
        setSlotParametersMode: (state, action) => {
            state.slotParametersMode = action.payload
        },
    }
})

export const {
    setSlotAvailableQuantity,
    setSlotPrice,
    setSlotCompanionPrice,
    setSlotParametersMode,
} = adminSlice.actions



export default adminSlice.reducer


