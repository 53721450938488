import { FC, useEffect, useState } from 'react'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from "./Layout"
import { MainTitle } from '../components/MainTitle';
import { IUser } from '../interfaces';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { EnumMessageType, EnumUserLegalStatus } from '../enums';
import { Spinner } from '../components/Spiner';
import { apolloClient } from '../App';
import { GQL_USER_BY_INVITATION_TOKEN } from '../graphql/Queries';
import { useMutation } from '@apollo/client';
import { NAVIGATE_ROOT, NAVIGATE_WORKSHOP_PAGE } from '../utils/Navigation';
import { useNavigate } from 'react-router-dom';
import { addMessage } from '../components/messages/Message';
import { useDispatch, useSelector } from 'react-redux';
import { setJwt } from '../redux/features/global/apolloSlice';
import { setCurrentUser } from '../redux/features/users/currentUserSlice';
import { GQL_MUTATION_CHOOSE_PASSWORD } from '../graphql/Mutations';
import { IRootState } from '../redux/store';

interface IProps {
}

export const InvitationPage: FC<IProps> = (props) => {

  const currentUser: (undefined | IUser) = useSelector((state: IRootState) => state.currentUserReducer.user)

  const [user, setUser] = useState<IUser | undefined>()
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [password, setPassword] = useState("")
  const [errorOnPassword, setErrorOnPassword] = useState(false)
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [errorOnPasswordConfirmation, setErrorOnPasswordConfirmation] = useState(false)

  const [choosePassword, { data: choosePasswordData, error: choosePasswordError }] = useMutation(GQL_MUTATION_CHOOSE_PASSWORD)

  useEffect(() => {
    // Fonction pour extraire les paramètres de l'URL
    const getQueryParam = (name: string) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get(name);
    };

    // Obtenir la valeur de la variable 'invitation_token' depuis l'URL
    const invitationToken = getQueryParam('invitation_token');

    if (invitationToken) {
      apolloClient.query({
        query: GQL_USER_BY_INVITATION_TOKEN,
        fetchPolicy: 'network-only',
        variables: {
          invitationToken,
        }
      }
      ).then(response => {
        setUser(response.data.userByInvitationToken)
      })
    }
  }, [])

  const checkErrors = () => {
    let res = true
    if (password === undefined || password === "") {
      setErrorOnPassword(true)
      res = false
    } else {
      setErrorOnPassword(false)
    }
    if (password !== passwordConfirmation) {
      setErrorOnPasswordConfirmation(true)
      res = false
    } else {
      setErrorOnPasswordConfirmation(false)
    }
    return res
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (user && checkErrors()) {
      choosePassword({
        variables: {
          userId: user.id,
          invitationToken: user.invitationToken,
          password,
        }
      })
    }
  }

  useEffect(() => {
    if (choosePasswordError) {
      addMessage({
        location: "InvitationPage",
        type: EnumMessageType.Error,
        message: choosePasswordError.message,
      })
    } else if (choosePasswordData) {
      if (choosePasswordData.choosePassword.statusCode === 200) {
        dispatch(setJwt(choosePasswordData.choosePassword.authToken))
        dispatch(setCurrentUser(choosePasswordData.choosePassword.user))
        addMessage({
          type: EnumMessageType.Success,
          message: "Mot de passe enregistré.",
        })
        navigate(NAVIGATE_WORKSHOP_PAGE)
      } else {
        choosePasswordData.choosePassword.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [choosePasswordData, choosePasswordError])



  return (
    <div className="InvitationPage">
      <Layout>
        <Flexbox direction='column'>
          <MainTitle title='Bienvenue au Mazéniène' />
          {
            currentUser && <>
              <Flexbox direction='column'
                sx={{
                  my: 4,
                }}
              >
                <Typography sx={{ fontSize: 16, color: theme.palette.primary.main }}>
                  Vous êtes déjà connecté en tant que {`${currentUser.firstname} ${currentUser.lastname} `}
                </Typography>
              </Flexbox>
            </>
          }
          {
            !currentUser && <>
              <Flexbox direction='column'
                sx={{
                  my: 4,
                }}
              >
                {
                  !user && <>
                    <Spinner />
                  </>
                }
                {
                  user && <>
                    {
                      user.legalStatus === EnumUserLegalStatus.enterprise &&
                      <Typography sx={{ fontSize: 24, color: theme.palette.primary.main }}>
                        {user.enterpriseName}
                      </Typography>
                    }
                    {
                      user.legalStatus === EnumUserLegalStatus.particular &&
                      <Typography sx={{ fontSize: 24, color: theme.palette.primary.main }}>
                        {`${user.firstname} ${user.lastname}`}
                      </Typography>
                    }
                  </>
                }

                {
                  user && <>
                    <Flexbox direction='column' sx={{ mx: 4, mt: 0 }}>
                      <Flexbox direction='column' sx={{ maxWidth: "600px" }}>

                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0 }}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            label="Email"
                            value={user.email}
                            disabled
                          />

                          <Typography color="text.primary" sx={{ fontFamily: theme.typography.secondary, fontSize: 24, mt: 4 }}>
                            Veuillez choisir votre mot de passe
                          </Typography>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            onChange={(event) => { setPassword(event.target.value) }}
                            value={password}
                            error={errorOnPassword}
                            helperText={errorOnPassword ? "Ce champ est obligatoire." : ""}
                          />
                          <Typography sx={{
                            color: theme.palette.text.disabled,
                          }}>
                            8 caractères minimum
                          </Typography>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="passwordConfirmation"
                            label="Confirmation"
                            type="password"
                            id="passwordConfirmation"
                            onChange={(event) => { setPasswordConfirmation(event.target.value) }}
                            value={passwordConfirmation}
                            error={errorOnPasswordConfirmation}
                            helperText={errorOnPasswordConfirmation ? "Les champs doivent correspondre." : ""}
                          />
                          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}
                          >
                            Enregistrer
                          </Button>
                        </Box>
                      </Flexbox>
                    </Flexbox>
                  </>
                }

              </Flexbox>
            </>
          }
        </Flexbox>
      </Layout>
    </div >
  )
}
