import { Box, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
// import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { Footer } from '../sections/Footer';
import { Messages } from '../components/messages/Messages';
import { ScrollToTop } from '../components/utils/ScrollToTop';
import { EnumBuildMode } from '../enums';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';


interface IProps {
  buildMode?: EnumBuildMode,
  children: ReactElement | ReactElement[];
}

export const Layout: FC<IProps> = (props) => {
  const theme = useTheme()



  return (
    <Box className="Layout" sx={{ backgroundColor: theme.palette.background.default }}>
      <Messages />
      <ResponsiveAppBar buildMode={props.buildMode} />
      <Box className='page-container' sx={{
        minHeight: "800px",
        px: "0 !important",
        backgroundColor: theme.palette.custom2.main,
        pt: { xs: 7, sm: 9 },
      }}>
        {props.children}
      </Box>
      <Footer />
      <ScrollToTop visible={false} activateOnLoad={true} />
    </Box>
  )
}
