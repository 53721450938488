import { Box, Button, Link, TextField, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { EnumMazenieneLogoMode, EnumMessageType } from '../enums'
import { useMutation } from '@apollo/client'
import { GQL_MUTATION_LOGIN, GQL_MUTATION_RESET_PASSWORD, GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL } from '../graphql/Mutations'
import { addMessage } from "../components/messages/Message"
import { useDispatch, useSelector } from 'react-redux'
import { setJwt } from '../redux/features/global/apolloSlice'
import { useNavigate } from 'react-router'
import { IRootState } from '../redux/store'
import { NAVIGATE_LOGIN_PAGE, NAVIGATE_ROOT } from '../utils/Navigation'
import { MazenieneLogo } from '../components/MazenieneLogo'
import { setCurrentUser } from '../redux/features/users/currentUserSlice'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from './Layout'
import { useParams } from "react-router-dom";

interface IProps {
}

export const PasswordResetPage: FC<IProps> = (props) => {
  const [email, setEmail] = useState("")
  const [errorOnEmail, setErrorOnEmail] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const [errorOnNewPassword, setErrorOnNewPassword] = useState(false)
  const [errorOnNewPasswordConfirmation, setErrorOnNewPasswordConfirmation] = useState(false)
  const [resetPassword, { data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError }] = useMutation(GQL_MUTATION_RESET_PASSWORD)
  const navigate = useNavigate()
  const theme = useTheme()
  const params = useParams()
  const passwordResetToken = params.passwordResetToken

  useEffect(() => {
    if (resetPasswordError) {
      addMessage({
        location: "PasswordResetPage",
        type: EnumMessageType.Error,
        message: resetPasswordError.message,
      })
    } else if (resetPasswordData) {
      if (resetPasswordData.resetPassword.statusCode === 200) {
        addMessage({
          type: EnumMessageType.Success,
          message: "Mot de passe réinitialisé."
        })
        navigate(NAVIGATE_LOGIN_PAGE)
      } else {
        resetPasswordData.resetPassword.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [resetPasswordData, resetPasswordError])

  const checkErrors = () => {
    let res = true
    if (email === undefined || email === "") {
      setErrorOnEmail(true)
      res = false
    } else {
      setErrorOnEmail(false)
    }
    if (newPassword === undefined || newPassword === "") {
      setErrorOnNewPassword(true)
      res = false
    } else {
      setErrorOnNewPassword(false)
    }
    if (newPasswordConfirmation === undefined || newPasswordConfirmation === "") {
      setErrorOnNewPasswordConfirmation(true)
      res = false
    } else {
      setErrorOnNewPasswordConfirmation(false)
    }
    if (newPassword !== newPasswordConfirmation) {
      addMessage({
        type: EnumMessageType.Error,
        message: "Le nouveau mot de passe et sa confirmation sont différents."
      })
      res = false
    }
    return res
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (checkErrors()) {
      resetPassword({
        variables: {
          email: email,
          passwordResetToken: passwordResetToken,
          newPassword: newPassword,
        }
      })
    }
  };


  return (
    <div className="PasswordResetPage">
      <Layout>
        <Flexbox direction='column' sx={{ mx: 4, mt: 4 }}>
          <Flexbox direction='column' sx={{ maxWidth: "600px" }}>

            <MazenieneLogo mode={EnumMazenieneLogoMode.standard} sx={{ width: "200px" }} />
            <Typography color="text.primary" sx={{ fontFamily: theme.typography.secondary, fontSize: 24 }}>
              Changement de mot de passe
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                variant="standard"
                onChange={(event) => { setEmail(event.target.value) }}
                value={email}
                required
                error={errorOnEmail}
                helperText={errorOnEmail ? "Ce champ est obligatoire." : ""}
                autoFocus
                name="email"
              />
              <TextField
                type='password'
                margin="dense"
                label="Nouveau mot de passe"
                fullWidth
                variant="standard"
                onChange={(event) => { setNewPassword(event.target.value) }}
                value={newPassword}
                required
                error={errorOnNewPassword}
                helperText={errorOnNewPassword ? "Ce champ est obligatoire." : ""}
              />
              <TextField
                type='password'
                margin="dense"
                label="Confirmez le mot de passe"
                fullWidth
                variant="standard"
                onChange={(event) => { setNewPasswordConfirmation(event.target.value) }}
                value={newPasswordConfirmation}
                required
                error={errorOnNewPasswordConfirmation}
                helperText={errorOnNewPasswordConfirmation ? "Ce champ est obligatoire." : ""}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Enregistrer
              </Button>
              <Flexbox>
                <Link href='#' onClick={() => navigate(NAVIGATE_LOGIN_PAGE)} variant="body2">
                  Se connecter
                </Link>
              </Flexbox>
            </Box>
          </Flexbox>
        </Flexbox>
      </Layout>
    </div >
  )
}
