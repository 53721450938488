import { FC } from 'react'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from "./Layout"
import { DescriptionSection } from '../sections/DescriptionSection'
import { MenuSection } from '../sections/MenuSection'
import { WorkshopSection } from '../sections/WorkshopSection'
import { EngagementsSection } from '../sections/EngagementsSection'
import { PrivatizeSection } from '../sections/PrivatizeSection'
import { EnumBuildMode } from '../enums'

interface IProps {
}

export const BuildingPage: FC<IProps> = (props) => {



  return (
    <div className="BuildingPage">
      <Layout buildMode={EnumBuildMode.building}>
        <Flexbox direction='column'>
          <DescriptionSection buildMode={EnumBuildMode.building} />
        </Flexbox>
      </Layout>
    </div >
  )
}
