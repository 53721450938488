import { createSlice } from "@reduxjs/toolkit";
import { apolloClient } from "../../../App";
import { GQL_USERS } from "../../../graphql/Queries";
import { IUser } from "../../../interfaces";
import { store } from "../../store";

interface IUsersSliceState {
    users: IUser[],
    usersLoading: boolean,
    searchUsersQuery: string,
}

const initialState: IUsersSliceState = {
    users: [],
    usersLoading: false,
    searchUsersQuery: "",
}

export const usersSlice = createSlice({
    name: "usersSlice",
    initialState,
    reducers: {
        setUsersAction: (state, action) => {
            state.users = action.payload
        },
        setUsersLoading: (state, action) => {
            state.usersLoading = action.payload
        },
        getUsers: (state) => {
            state.usersLoading = true
            fetchUsers(state.searchUsersQuery)
        },
        setUsersQuery: (state, action) => {
            state.searchUsersQuery = action.payload
            state.usersLoading = true
            fetchUsers(state.searchUsersQuery)
        },
    }
})

export const { setUsersAction, getUsers, setUsersQuery, setUsersLoading
} = usersSlice.actions

const fetchUsers = (searchQuery: string) => {
    apolloClient.query({
        query: GQL_USERS,
        fetchPolicy: 'network-only',
        variables: {
            searchQuery: searchQuery,
        }
    }
    ).then(response => {
        store.dispatch(setUsersAction(response.data.users))
        store.dispatch(setUsersLoading(false))
    })
}

export default usersSlice.reducer