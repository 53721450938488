import { Container, useTheme } from '@mui/material'
import { FC } from 'react'
import { PuffLoader } from 'react-spinners'

interface IProps {
    size?: number
}

// https://www.davidhu.io/react-spinners/
export const Spinner: FC<IProps> = (props) => {
    const theme = useTheme()
    // const color = "#1EA8C0"
    const color = theme.palette.primary.main
    return (
        <PuffLoader color={color} size={props.size || 60} />
    )
}
