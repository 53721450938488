import { FC } from 'react'
import { Flexbox } from '../../components/utils/Flexbox'
import { Layout } from "../Layout"
import { DescriptionSection } from '../../sections/DescriptionSection'
import { MenuSection } from '../../sections/MenuSection'
import { WorkshopSection } from '../../sections/WorkshopSection'
import { EngagementsSection } from '../../sections/EngagementsSection'
import { PrivatizeSection } from '../../sections/PrivatizeSection'
import { Footer } from '../../sections/Footer'
import { MazenieneLogo } from '../../components/MazenieneLogo'
import { EnumDevice, EnumMazenieneLogoMode, EnumWorkshopPlanningMode } from '../../enums'
import { Button } from '@mui/material'
import { AdminLayout } from './AdminLayout'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_ADMIN_SLOTS_PAGE } from '../../utils/Navigation'
import { MainTitle } from '../../components/MainTitle'
import { WorkshopPlanning } from '../../components/WorkshopPlanning'
import { SlotParameters } from '../../components/SlotParameters'

interface IProps {
}

export const AdminSlotsPage: FC<IProps> = (props) => {

  const navigate = useNavigate()


  return (
    <div className="AdminSlotsPage">
      <AdminLayout>
        <Flexbox direction='column'>
          <MainTitle title="Créneaux atelier cuisine" />
          <Flexbox direction='column'>
            <SlotParameters />
            <WorkshopPlanning mode={EnumWorkshopPlanningMode.admin} device={EnumDevice.desktop} />
          </Flexbox>
        </Flexbox>
      </AdminLayout>
    </div >
  )
}
